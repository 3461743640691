import React, { useState, useEffect } from "react";
import {
  Paper,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Skeleton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SecurityIcon from "@mui/icons-material/Security";
import * as Actions from "../../Redux/Action";
import PermissionManagementModal from "./PermissionManagementModal";
import { useSelector, useDispatch } from "react-redux";
import baseUrl from "../../Config/config";
import "../../Styles/Sports.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const UserManagementTable = () => {
  const isloading = useSelector((state) => state.isLoading);
  //state variables
  const adminpermissions = useSelector((state) => state.permissionsData);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [id, setId] = useState();
  const [itemId, setItemId] = useState();
  const [userMngmntOpen, setUserMngmntOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const createdUser = useSelector((state) => state.userData);
  const token = localStorage.getItem("AccessToken");

  const LIST_ADMIN = `${baseUrl.baseUrl}api/all-admin/`;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = () => {
    axios
      .get(LIST_ADMIN, { headers })
      .then(function (response) {
        setIsLoaded(true);
        handleData(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [createdUser && createdUser]);

  const handleData = (results) => {
    const updatedRows = results.map((item,index) => {
      return {
        key:index,
        email: item.email,
        firstname: item.first_name,
        lastname: item.last_name,
        username: item.username,
        createdon: formatDate(item.created_date),
        accesscontrol: (
          <Button
            style={{
              color: "#1c226b",
              textTransform: "capitalize",
            }}
            onClick={() =>
              adminpermissions &&
              adminpermissions.permission?.UserManagement?.update
                ? handleUserMngmntOpen(item.id)
                : toast.error("Access denied", { duration: 1000 })
            }
          >
            <SecurityIcon />
            <span>Grant/Revoke Access</span>
          </Button>
        ),
        actions: (
          <Button
            style={{ color: "#f44336" }}
            onClick={() =>
              adminpermissions &&
              adminpermissions.permission?.UserManagement?.delete
                ? handleConfirm(item.id)
                : toast.error("Access denied", { duration: 1000 })
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        ),
      };
    });
    setRows(updatedRows);
  };

  const formatDate = (val) => {
    const dateVal = new Date(val);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = dateVal.toLocaleDateString("en-GB", options);
    const formattedTime = dateVal.toLocaleTimeString();
    return `${formattedDate},${formattedTime}`;
  };

  const handleDelete = (_id) => {
    dispatch(Actions.sendIsLoading(true));
    let DELETE_API = `${baseUrl.baseUrl}api/delete-admin/?id=${_id}`;
    axios
      .delete(DELETE_API, { headers })
      .then(function (response) {
        dispatch(Actions.sendIsLoading(false));
        fetchData();
        handleConfirmClose();
        dispatch(Actions.sendIsUserDeleted(true));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAccessControlClose = () => {
    setOpen(false);
  };

  const handleUserMngmntOpen = (id) => {
    setId(id);
    setOpen(true);
    setUserMngmntOpen(true);
  };

  const columns = [
    { id: "email", label: "Email" },
    { id: "firstname", label: "First Name" },
    { id: "lastname", label: "Last Name" },
    {
      id: "username",
      label: "User Name",
    },
    {
      id: "createdon",
      label: "Created on",
    },
    {
      id: "accesscontrol",
      label: "Access Control",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const handleConfirm = (betId) => {
    setItemId(betId);
    setConfirmModalOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmModalOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // setPage(0);
  };

  return (
    <>
      {isLoaded ? (
        <div className="table-sports">
          <Paper
            sx={{
              width: "100%",
              backgroundColour: "black",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center" id="table-row">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                  {rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  style={{ padding: "9px" }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <div>
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={open}
              onClose={handleAccessControlClose}
            >
              <div>
                <PermissionManagementModal
                  open={userMngmntOpen}
                  onClose={handleAccessControlClose}
                  id={id}
                />
              </div>
            </Dialog>
          </div>
          <div>
            <Dialog open={confirmModalOpen} onClose={handleConfirmClose}>
              <DialogTitle
                style={{
                  textAlign: "center",
                  color: "#1c226b",
                  fontWeight: "bolder",
                }}
              >
                <p>Are you sure you want to delete this item ?</p>
              </DialogTitle>
              <DialogContent
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Button
                  variant="outlined"
                  style={{ marginRight: "10px" }}
                  onClick={() => handleConfirmClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleDelete(itemId)}
                >
                  Confirm
                </Button>
              </DialogContent>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isloading && isloading}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CircularProgress
                    size={"4rem"}
                    style={{
                      color: "#1c226b",
                      margin: "auto",
                    }}
                  />
                </Box>
              </Backdrop>
            </Dialog>
          </div>
        </div>
      ) : (
        <div className="table-sports">
          <Paper
            sx={{
              width: "100%",
              backgroundColour: "black",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table aria-label="table skeleton">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center" id="table-row">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      {[...Array(7)].map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  );
};

export default UserManagementTable;
