import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function ChallengeTypePie(props) {
  const pieData = useSelector((state) => state.graphData);

  let options;

  if (pieData) {
    const privateData = parseFloat(pieData.privatePercent.replace("%", ""));
    const publicData = parseFloat(pieData.PublicPercent.replace("%", ""));
    Highcharts.setOptions({
      colors: ["#8085E9", "#A9FF96"],
      chart: {
        backgroundColor: "white",
        borderWidth: 0,
        plotBackgroundColor: "rgba(255, 255, 255, .9)",
        plotShadow: true,
        plotBorderWidth: 0,
      },
      credits: {
        enabled: false,
      },
    });

    options = {
      colors: ["#8085E9", "#A9FF96"],
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height:400,
        width:400,
        type: "pie",
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
      },
      title: {
        text: "Challenge Types",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: [
            {
              name: "Private",
              y: privateData,
              sliced: true,
              selected: true,
            },
            {
              name: "Public",
              y: publicData,
            },
          ],
        },
      ],
    };
  } else if (props.data && props.data) {
    const privateData = parseFloat(props.data.privatePercent.replace("%", ""));
    const publicData = parseFloat(props.data.PublicPercent.replace("%", ""));

    Highcharts.setOptions({
      colors: ["#8085E9", "#A9FF96"],
      chart: {
        backgroundColor: "#fff",
        borderWidth: 0,
        plotBackgroundColor: "rgba(255, 255, 255, .9)",
        plotShadow: true,
        plotBorderWidth: 0,
      },
      credits: {
        enabled: false,
      },
    });

    options = {
      colors: ["#8085E9", "#A9FF96"],
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height:400,
        width:400,
        type: "pie",
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
      },
      title: {
        text: "Challenge Types",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: [
            {
              name: "Private",
              y: privateData,
              sliced: true,
              selected: true,
            },
            {
              name: "Public",
              y: publicData,
            },
          ],
        },
      ],
    };
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
