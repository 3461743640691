import React from "react";
// import PublicRouter from "./PublicRoutes";
import PrivateRouter from "./PrivateRoutes";
import { useLocation, useNavigate } from "react-router-dom";

export default function Routers() {
    const navigate = useNavigate();
    React.useEffect(() => {
        return navigate('Admin/Dashboard');
    }, []);
    return (
        <>
            <PrivateRouter />
        </>
    );
}