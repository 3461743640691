import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useDispatch , useSelector } from "react-redux";
import { SocketCall } from "../../utils/WebSockets";
import * as Actions from "../../Redux/Action";
import baseUrl from "../../Config/config";
import "../../Styles/Markets.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MarketModal = (props) => {
  const isloading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [marketName, setMarketName] = useState();
  const [marketType, setMarketType] = useState();
  const [allSports, setAllSports] = useState();
  const [activedSports, setActivedSports] = useState();
  const [selectedSports, setSelectedSports] = useState([]);
  const [type, setType] = useState();
  const [isSports, setIsSports] = useState(true);
  const [typeError, setTypeError] = useState();
  const [sport, setSport] = useState();
  const websocket = new SocketCall();
  const CREATE_API = `${baseUrl.baseUrl}api/market/`;
  const SPORT_LIST = `${baseUrl.baseUrl}api/sports/get_sport/`;
  const token = localStorage.getItem("AccessToken");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    axios
      .get(SPORT_LIST, { headers })
      .then(function (response) {
        setActivedSports(response.data.sports);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleBackToList = () => {
    props.onClose();
  };

  const updateType = (e, setState) => {
    validate(e.target.value, setState);
  };

  const validate = (type, setState) => {
    if (!type) {
      setState("Required*");
    } else {
      setState("");
    }
  };

  const handleMarketName = (e) => {
    setMarketName(e.target.value);
  };
  const handleType = (e) => {
    validate(e.target.value, setTypeError);
    setType(e.target.value);
    const typeData = { type: e.target.value };
    const reqApi = {
      command: "request_session",
      params: {
        site_id: 1334,
        language: "en",
      },
    };
    const allSports = {
      command: "get",
      params: {
        source: "betting",
        what: {
          sport: [],
        },
        where: {
          market: typeData,
          sport: {
            id: {
              "@in": activedSports,
            },
          },
        },
      },
    };
    (async () => {
      var response = await websocket.sendcommand(allSports);
      const sportsData =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.sport;

      if (sportsData) {
        const sportsArr = Object.values(sportsData);
        const allSports = sportsArr.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setAllSports(allSports);
        setIsSports(false);
      }
    })();
  };
  const handleMarketType = (e) => {
    setMarketType(e.target.value);
  };
  const handleSport = (e) => {
    setSelectedSports(e.target.value);
  };

  const handleSubmit = (e) => {
    dispatch(Actions.sendIsLoading(true));
    const data = {
      sport_id: selectedSports,
      market_type: marketType,
      type: type,
      market_name: marketName,
    };

    axios
      .post(CREATE_API, data, { headers })
      .then(function (response) {
        dispatch(Actions.sendIsLoading(false));
        dispatch(Actions.sendCreatedMarkets(response.data));
        toast.success("Successfully Created", { duration: 1000 });
        props.onClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <DialogTitle>
        <Button
          variant="outlined"
          style={{
            color: "#1c226b",
            textTransform: "capitalize",
          }}
          onClick={() => handleBackToList()}
        >
          <ArrowBackIosIcon style={{ width: "14px" }} />
          &nbsp;Back to List
        </Button>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              label="Type"
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
              onChange={(e) => updateType(e, setTypeError)}
              onBlur={(e) => handleType(e)}
            />
            {typeError && <p className="error">{typeError}</p>}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              label="Market Name"
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              value={marketName}
              onChange={(e) => handleMarketName(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              label="Market Type"
              size="small"
              variant="outlined"
              style={{ width: "100%" }}
              value={marketType}
              onChange={(e) => handleMarketType(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              style={{ width: "100%", marginRight: "10px" }}
              size="small"
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Sports Name
              </InputLabel>
              <Select
                style={{ width: "100%", height: "100%" }}
                labelId="demo-multiple-checkbox-label"
                id="sports"
                multiple
                value={selectedSports}
                onChange={(e) => handleSport(e)}
                input={<OutlinedInput label="MarketType" />}
                MenuProps={MenuProps}
                disabled={isSports}
              >
                {allSports &&
                  allSports.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.id} {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid
        container
        style={{ justifyContent: "center", marginBottom: "30px" }}
      >
        <Grid item xs={6}>
          <Button
            className="submit-markbtn"
            variant="contained"
            style={{
              width: "100%",
              backgroundColor: "#1c226b",
            }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isloading && isloading}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CircularProgress
            size={"4rem"}
            style={{
              color: "#1c226b",
              margin: "auto",
            }}
          />
        </Box>
      </Backdrop>
    </React.Fragment>
  );
};

export default MarketModal;
