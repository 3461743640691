import React, { useState } from "react";
import { Paper, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "../../Styles/Reports.css";

const columns = [
  { id: "challenge", label: "Challenge Id" },
  {
    id: "created_user",
    label: "Created User",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "type_of_challenge",
    label: "Challenge Type",
  },
  {
    id: "sport_name",
    label: "Sport Name",
  },
  {
    id: "game_name",
    label: "Game Name",
    width: "100px",
  },
  {
    id: "market_name",
    label: "Market Name",
  },
  {
    id: "event_name",
    label: "Event Name",
  },
  {
    id: "stake_amount",
    label: "Stake Amount",
  },
  {
    id: "result",
    label: "Result",
  },
  {
    id: "match_wining_amount",
    label: "Match Winning Amount",
  },
  {
    id: "tax",
    label: "Tax",
  },
];

const Reports = (props) => {
  const rows = props.data.map((item) => ({
    challenge: item.challenge,
    created_user: item.created_user,
    status: item.status,
    type_of_challenge: item.type_of_challenge,
    sport_name: item.sport_name,
    game_name: (
      <Button onClick={() => openGameNameDialog(item.game_name)}>View</Button>
    ),
    market_name: item.market_name,
    event_name: item.event_name,
    stake_amount: item.stack_amount,
    result: <Button onClick={() => openResultDialog(item.result)}>View</Button>,
    match_wining_amount: item.match_wining_amount,
    tax: item.tax,
  }));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [gameNames, setGameNames] = useState();
  const [gameOpen, setGameOpen] = useState(false);
  const [results, setResults] = useState();
  const [resultOpen, setResultOpen] = useState(false);

  const openGameNameDialog = (name) => {
    setGameNames(name);
    setGameOpen(true);
  };

  const openResultDialog = (name) =>{
    setResults(name);
    setResultOpen(true);
  } 

  const handleGameClose = () => {
    setGameOpen(false);
  };
  const handleResultClose = () => {
    setResultOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <div>
        <Paper
          sx={{ width: "100%", backgroundColour: "black", overflow: "hidden" }}
        >
          <TableContainer sx={{width:"100%"}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center" id="table-row">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows && rows.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
                {rows &&
                  rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align="center"
                                sx={{ width: column.width }}
                                style={{padding:"9px"}}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Dialog
            PaperProps={{
              style: {
                backgroundColor: "white",
                boxShadow: "none",
              },
            }}
            open={gameOpen}
            onClose={handleGameClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontSize: "16px",
                fontFamily: "Roboto,Arial,Helvetica,sans-serif",
              }}
            >
              <p>{gameNames}</p>
            </DialogTitle>
          </Dialog>
          <Dialog
            PaperProps={{
              style: {
                backgroundColor: "white",
                boxShadow: "none",
              },
            }}
            open={resultOpen}
            onClose={handleResultClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontSize: "16px",
                fontFamily: "Roboto,Arial,Helvetica,sans-serif",
              }}
            >
              <p>{results}</p>
            </DialogTitle>
          </Dialog>
        </Paper>
      </div>
    </div>
  );
};

export default Reports;
