import React, { useEffect, useState } from "react";
import {
  DialogContent,
  TextField,
  Button,
  Grid,
  Checkbox,
} from "@mui/material";
import "../../Styles/Sports.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import baseUrl from "../../Config/config";
import axios from "axios";
import { useDispatch,useSelector } from "react-redux";
import * as Actions from "../../Redux/Action";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SecurityIcon from "@mui/icons-material/Security";
import "../../Styles/UserManagement.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

export default function UserManagementModal(props) {
  const isloading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [userInfoOpen, setUserInfoOpen] = useState(true);
  const [accessMngmntOpen, setAccessMngmntOpen] = useState(false);
  const CREATE_API = `${baseUrl.baseUrl}api/create-admin-user/`;
  const token = localStorage.getItem("AccessToken");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [dashboardView, setDashboardView] = useState(false);
  const [dashboardCreate, setDashboardCreate] = useState(false);
  const [dashboardUpdate, setDashboardUpdate] = useState(false);
  const [dashboardDelete, setDashboardDelete] = useState(false);

  const [marketView, setMarketView] = useState(false);
  const [marketCreate, setMarketCreate] = useState(false);
  const [marketUpdate, setMarketUpdate] = useState(false);
  const [marketDelete, setMarketDelete] = useState(false);

  const [refundView, setRefundView] = useState(false);
  const [refundCreate, setRefundCreate] = useState(false);
  const [refundUpdate, setRefundUpdate] = useState(false);
  const [refundDelete, setRefundDelete] = useState(false);

  const [sportView, setSportView] = useState(false);
  const [sportCreate, setSportCreate] = useState(false);
  const [sportUpdate, setSportUpdate] = useState(false);
  const [sportDelete, setSportDelete] = useState(false);

  const [configureView, setconfigureView] = useState(false);
  const [configureCreate, setconfigureCreate] = useState(false);
  const [configureUpdate, setconfigureUpdate] = useState(false);
  const [configureDelete, setconfigureDelete] = useState(false);

  const [usermanagementView, setusermanagementView] = useState(false);
  const [usermanagementCreate, setusermanagementCreate] = useState(false);
  const [usermanagementUpdate, setusermanagementUpdate] = useState(false);
  const [usermanagementDelete, setusermanagementDelete] = useState(false);

  const [reportsView, setreportsView] = useState(false);
  const [reportsCreate, setreportsCreate] = useState(false);
  const [reportsUpdate, setreportsUpdate] = useState(false);
  const [reportsDelete, setreportsDelete] = useState(false);

  const [CarouselImageView, setcarouselImageView] = useState();
  const [CarouselImageCreate, setcarouselImageCreate] = useState();
  const [CarouselImageUpdate, setcarouselImageUpdate] = useState();
  const [CarouselImageDelete, setcarouselImageDelete] = useState();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [emailerror, setemailerror] = useState(false)

  const handleChange = (e, setter, email) => {
    setter(e.target.value);
    //regex email validation
    if(email){
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (e.target.value.match(mailformat)) {
        setemailerror(false)
      }
      else{
        setemailerror(true)
      }
    }
    
    if (e.target.value !== "" && email !== "" && userName !== "" && !emailerror) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleClear = () => {
    setFirstName("");
    setLastName("");
    setUserName("");
    setEmail("");
    setIsButtonDisabled(true);
  };

  const [state, setState] = React.useState(null);

  const onSubmitHandler = () => {
    dispatch(Actions.sendIsLoading(true));
    const randomNumber = Math.floor(Math.random() * 900000) + 100000;

    const UpdateData = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      username: userName,
      p2p_user_id: randomNumber,
      password: "Test1234",
      permission: {
        Dashboard: {
          view: dashboardView,
          create: dashboardCreate,
          update: dashboardUpdate,
          delete: dashboardDelete,
        },
        Sport: {
          view: sportView,
          create: sportCreate,
          update: sportUpdate,
          delete: sportDelete,
        },
        Market: {
          view: marketView,
          create: marketCreate,
          update: marketUpdate,
          delete: marketDelete,
        },
        Refund: {
          view: refundView,
          create: refundCreate,
          update: refundUpdate,
          delete: refundDelete,
        },
        Configure: {
          view: configureView,
          create: configureCreate,
          update: configureUpdate,
          delete: configureDelete,
        },
        UserManagement: {
          view: usermanagementView,
          create: usermanagementCreate,
          update: usermanagementUpdate,
          delete: usermanagementDelete,
        },
        Reports: {
          view: reportsView,
          create: reportsCreate,
          update: reportsUpdate,
          delete: reportsDelete,
        },
        CarouselImages: {
          view: CarouselImageView,
          create: CarouselImageCreate,
          update: CarouselImageUpdate,
          delete: CarouselImageDelete,
        },
      },
    };

    axios
      .post(CREATE_API, UpdateData, { headers })
      .then(function (response) {
        dispatch(Actions.sendIsLoading(false));
        dispatch(Actions.sendCreatedUsers(UpdateData));
        dispatch(Actions.sendIsUserCreated(true));
        props.onClose();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleUserInfo = () => {
    const element = document.getElementById("access_mngmnt");
    element.classList.add("disable_button");
    const element1 = document.getElementById("user_info");
    element1.classList.remove("disable_button");
    setUserInfoOpen(true);
    setAccessMngmntOpen(false);
  };

  const handleAccessMngmnt = () => {
    const element = document.getElementById("access_mngmnt");
    element.classList.remove("disable_button");
    const element1 = document.getElementById("user_info");
    element1.classList.add("disable_button");
    setAccessMngmntOpen(true);
    setUserInfoOpen(false);
  };

  const handleCreateClose = () => {
    props.onClose();
  };

  return (
    <div>
      <Grid container style={{ justifyContent: "end", padding: "5px" }}>
        <CloseIcon
          onClick={() => handleCreateClose()}
          style={{ cursor: "pointer" }}
        />
      </Grid>
      <DialogContent style={{ paddingTop: "10px" }}>
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={6} style={{ paddingRight: "3px" }}>
            <Button
              id="user_info"
              style={{
                backgroundColor: "#1c226b",
                width: "100%",
                height: "100%",
                borderRadius: "0px",
                textTransform: "capitalize",
              }}
              variant="contained"
              onClick={() => handleUserInfo()}
              // className="disable_button"
            >
              <PersonAddAltIcon style={{ marginRight: "5px" }} /> User
              Information
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className="disable_button"
              id="access_mngmnt"
              style={{
                backgroundColor: "#1c226b",
                width: "100%",
                height: "100%",
                color: "#fff",
                borderRadius: "0px",
                textTransform: "capitalize",
              }}
              variant="contained"
              disabled={isButtonDisabled}
              onClick={() => handleAccessMngmnt()}
            >
              <SecurityIcon /> Access Management
            </Button>
          </Grid>
        </Grid>
        {userInfoOpen && (
          <div>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  label="User Name"
                  variant="standard"
                  style={{ width: "100%", marginBottom: "10px" }}
                  value={userName}
                  onChange={(e) => handleChange(e, setUserName)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  variant="standard"
                  style={{ width: "100%", marginBottom: "10px" }}
                  value={email}
                  onChange={(e) => handleChange(e, setEmail, email)}
                  error={emailerror}
                  helperText={emailerror ? "please enter valid email address" : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  label="First Name"
                  variant="standard"
                  style={{ width: "100%", marginBottom: "10px" }}
                  value={firstName}
                  onChange={(e) => handleChange(e, setFirstName)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  variant="standard"
                  style={{ width: "100%", marginBottom: "10px" }}
                  value={lastName}
                  onChange={(e) => handleChange(e, setLastName)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{ justifyContent: "end", marginTop: "10px" }}
            >
              <Grid item xs={2} style={{ paddingRight: "2px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#1c226b",
                    width: "100%",
                  }}
                  onClick={(e) => handleClear(e)}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isButtonDisabled}
                  onClick={() => handleAccessMngmnt()}
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#fff",
                    color: "#1c226b",
                    border: "1px solid #1c226b",
                    width: "100%",
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        {accessMngmntOpen && (
          <>
            <TableContainer
              component={Paper}
              style={{ marginTop: "20px", maxWidth: "100%" }}
            >
              <Table
                sx={{ minWidth: 535, overflowX: "hidden" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Module</TableCell>
                    <TableCell align="right">View</TableCell>
                    <TableCell align="right">Create</TableCell>
                    <TableCell align="right">Update</TableCell>
                    <TableCell align="right">Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      Sports
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={sportView}
                        onChange={(e) => setSportView(!sportView)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={sportCreate}
                        onChange={(e) => setSportCreate(!sportCreate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={sportUpdate}
                        onChange={(e) => setSportUpdate(!sportUpdate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={sportDelete}
                        onChange={(e) => setSportDelete(!sportDelete)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      Markets
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={marketView}
                        onChange={(e) => setMarketView(!marketView)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={marketCreate}
                        onChange={(e) => setMarketCreate(!marketCreate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={marketUpdate}
                        onChange={(e) => setMarketUpdate(!marketUpdate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={marketDelete}
                        onChange={(e) => setMarketDelete(!marketDelete)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      Refund
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={refundView}
                        onChange={(e) => setRefundView(!refundView)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={refundCreate}
                        onChange={(e) => setRefundCreate(!refundCreate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={refundUpdate}
                        onChange={(e) => setRefundUpdate(!refundUpdate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={refundDelete}
                        onChange={(e) => setRefundDelete(!refundDelete)}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      Reports
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={reportsView}
                        onChange={(e) => setreportsView(!reportsView)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={reportsCreate}
                        onChange={(e) => setreportsCreate(!reportsCreate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={reportsUpdate}
                        onChange={(e) => setreportsUpdate(!reportsUpdate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={reportsDelete}
                        onChange={(e) => setreportsDelete(!reportsDelete)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      User Management
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={usermanagementView}
                        onChange={(e) =>
                          setusermanagementView(!usermanagementView)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={usermanagementCreate}
                        onChange={(e) =>
                          setusermanagementCreate(!usermanagementCreate)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={usermanagementUpdate}
                        onChange={(e) =>
                          setusermanagementUpdate(!usermanagementUpdate)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={usermanagementDelete}
                        onChange={(e) =>
                          setusermanagementDelete(!usermanagementDelete)
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      Configure
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={configureView}
                        onChange={(e) => setconfigureView(!configureView)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={configureCreate}
                        onChange={(e) => setconfigureCreate(!configureCreate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={configureUpdate}
                        onChange={(e) => setconfigureUpdate(!configureUpdate)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={configureDelete}
                        onChange={(e) => setconfigureDelete(!configureDelete)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="td" scope="row">
                      Carousel Images
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={CarouselImageView}
                        onChange={(e) =>
                          setcarouselImageView(!CarouselImageView)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={CarouselImageCreate}
                        onChange={(e) =>
                          setcarouselImageCreate(!CarouselImageCreate)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={CarouselImageUpdate}
                        onChange={(e) =>
                          setcarouselImageUpdate(!CarouselImageUpdate)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={CarouselImageDelete}
                        onChange={(e) =>
                          setcarouselImageDelete(!CarouselImageDelete)
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              container
              style={{ justifyContent: "center", marginTop: "10px" }}
            >
              <Grid item xs={2} style={{ paddingRight: "2px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#1c226b",
                    width: "100%",
                  }}
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isloading && isloading}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CircularProgress
            size={"4rem"}
            style={{
              color: "#1c226b",
              margin: "auto",
            }}
          />
        </Box>
      </Backdrop>
    </div>
  );
}
