import React from "react";
import { toast } from "react-hot-toast";

var ws = "";
const requestid = Math.random().toString(36).substring(2, 7);
const EstablishSocketConnection = () => {
  ws = new WebSocket("wss://eu-swarm-gorillagames.betconstruct.com");
  var startsession = {
    command: "request_session",
    params: {
      site_id: 1334,
      language: "en",
    },
    rid: requestid,
  };

  ws.onopen = () => {
    if (ws.readyState === 1) {
      localStorage.setItem("Readystate", ws.readyState);
      ws.send(JSON.stringify(startsession));
    }
  };

//   ws.onclose = (e) => {
//     setTimeout(() => {
//       EstablishSocketConnection();
//     }, 5000);
//   };
  return ws;
};
export { EstablishSocketConnection };
export class SocketCall {
  sendcommand(command) {
    return new Promise((resolve, reject) => {
      // Wait for WebSocket connection to be established
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(command));
      } else {
        ws.addEventListener(
          "open",
          () => {
            ws.send(JSON.stringify(command));
          },
          { once: true }
        );
      }

      // Listen for response
      ws.addEventListener(
        "message",
        (event) => {
          const response = JSON.parse(event.data);
          resolve(response);
        },
        { once: true }
      );

      // Handle errors
      ws.addEventListener(
        "error",
        (event) => {
          toast.error("something went wrong, please try again");
          reject(event);
        },
        { once: true }
      );
    });
  }
}
