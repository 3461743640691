import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useSelector , useDispatch } from "react-redux";
import axios from "axios";
import * as Actions from "../../Redux/Action";
import baseUrl from "../../Config/config";
import { toast } from "react-hot-toast";

export default function ConfigureComp(props) {
  const adminpermissions = useSelector((state) => state.permissionsData);
  const token = localStorage.getItem("AccessToken");
  const [firstStake, setFirstStake] = useState();
  const [secondStake, setSecondStake] = useState();
  const [initialValue, setInitialValue] = useState({});
  const [thirdStake, setThirdStake] = useState();
  const [tax, setTax] = useState();
  const [ggComission, setGGComission] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Actions.sendIsLoading(true));
    axios
      .get(`${baseUrl.baseUrl}api/constants/get_constant/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(Actions.sendIsLoading(false));
        setInitialValue(response.data.results[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function handleFirstStakeChange(e) {
    setFirstStake(e.target.value);
  }
  function handleSecondStakeChange(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setSecondStake(e.target.value);
    }
  }
  function handleThirdStakeChange(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setThirdStake(e.target.value);
    }
  }
  function handleTaxChange(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setTax(e.target.value);
    }
  }
  function handleGGComissionChange(e) {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setGGComission(e.target.value);
    }
  }
  function handleSubmit() {
    dispatch(Actions.sendIsLoading(true));
    const fieldData = {
      firststake: Number(firstStake),
      secondstake: Number(secondStake),
      thirdstake: Number(thirdStake),
      Tax: Number(tax),
      GG_Commission: Number(ggComission)
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .put(`${baseUrl.baseUrl}api/constants/`, fieldData, { headers })
      .then((response) => {
        dispatch(Actions.sendIsLoading(false));
        toast.success("updated successfully", { duration: 1000 });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        marginTop: "50px",
        width: { xs: "90%", sm: "60%", md: "40%", lg: "30%" },
        gap: "8px",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <Typography
          component="span"
          sx={{
            alignSelf: "flex-start",
            textAlign: "left",
            color: "#1c226b",
            fontSize: "14px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "600",
          }}
        >
          First Stake
        </Typography>

        <TextField
          size="small"
          fullWidth
          value={
            firstStake !== undefined
              ? firstStake
              : (initialValue && initialValue.firststake) || ""
          }
          onChange={handleFirstStakeChange}
          type="number"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <Typography
          component="span"
          sx={{
            alignSelf: "flex-start",
            textAlign: "left",
            color: "#1c226b",
            fontSize: "14px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "600",
          }}
        >
          Second Stake
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={
            secondStake !== undefined
              ? secondStake
              : (initialValue && initialValue.secondstake) || ""
          }
          onChange={handleSecondStakeChange}
          type="number"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <Typography
          component="span"
          sx={{
            alignSelf: "flex-start",
            textAlign: "left",
            color: "#1c226b",
            fontSize: "14px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "600",
          }}
        >
          Third Stake
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={
            thirdStake !== undefined
              ? thirdStake
              : (initialValue && initialValue.thirdstake) || ""
          }
          onChange={handleThirdStakeChange}
          type="number"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <Typography
          component="span"
          sx={{
            alignSelf: "flex-start",
            textAlign: "left",
            color: "#1c226b",
            fontSize: "14px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "600",
          }}
        >
          Tax in percentage
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={
            tax !== undefined ? tax : (initialValue && initialValue.Tax) || ""
          }
          onChange={handleTaxChange}
          type="number"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <Typography
          component="span"
          sx={{
            alignSelf: "flex-start",
            textAlign: "left",
            color: "#1c226b",
            fontSize: "14px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "600",
          }}
        >
          GG Comission in percentage
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={
            ggComission !== undefined
              ? ggComission
              : (initialValue && initialValue.GG_Commission) || ""
          }
          onChange={handleGGComissionChange}
          type="number"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={() => {
            adminpermissions && adminpermissions.permission?.Configure.update
              ? handleSubmit()
              : toast.error("Access denied",{ duration: 1000 });
          }}
          sx={{
            background: "#1c226b",
            "&:hover": {
              background: "#1c226b",
            },
            margin: "20px 0px",
            padding: "8px 0px",
            fontSize: "14px",
          }}
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
}
