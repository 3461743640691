import React from "react";
import { useNavigate } from "react-router-dom";
import errorimage from "../Assets/AccessDenied.jpg";

export default function AccessDenied() {
    const navigate = useNavigate();
    return (
        <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column' }}>
            <img id="errorimage" src={errorimage} alt="CRG-p2p-admin-error-image" style={{ height: '80vh', width: 'auto' }} />
            <div>Access denied, please try contacting your super admin</div>
            <div>
                <button onClick={() => navigate("/Admin/Dashboard")}>go back</button>
            </div>
        </div>
    )
}