import { useCallback, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import update from "immutability-helper";
import { Card } from "./Card.js";
import axios from "axios";
import baseUrl from "../../Config/config.js";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import "../../Styles/ImageUpload.css";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../Redux/Action";

const style = {
  width: "100%",
  height: "88%",
};

const containerStyle = {
  // border: "1px solid #000",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "#000",
  color: "#fff",
  cursor: "move",
};

export default function ViewImages(props) {
  const token = localStorage.getItem("AccessToken");
  const adminpermissions = useSelector((state) => state.permissionsData);
  const isloading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [cards, setCards] = useState(props.images);
  const [reorder, setreorder] = useState(false);
  var headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards(
      (prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      setreorder(false)
    );
    setreorder(true);
  }, []);

  const deleteimage = (image_id) => {
    dispatch(Actions.getImagelist());
    dispatch(Actions.sendIsLoading(true));
    axios
      .delete(`${baseUrl.baseUrl}api/images/${image_id}/`, { headers })
      .then((response) => {
        dispatch(Actions.getImagelist(response.data.message));
        toast.success(response.data.message);
      })
      .catch((err) => toast.error(err, { duration: 1000 }));
  };

  const getupdatedimages = () => {
    axios
      .get(`${baseUrl.baseUrl}api/images/`, { headers })
      .then((res) => {
        setCards(res.data);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    setCards(props.images);
  }, [props.images]);

  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        //name={card.name}
        card={card}
        moveCard={moveCard}
        callback={deleteimage}
        getnewlist={getupdatedimages}
      />
    );
  }, []);

  // reorder the images
  useEffect(() => {
    axios
      .put(`${baseUrl.baseUrl}api/images/`, cards, { headers })
      .then((res) => {})
      .catch((err) => console.log("response", err));
  }, [cards]);

  return (
    <>
      <Grid container style={{ ...containerStyle }}>
        <Grid item xs={2} className="card-grid">
          Order
        </Grid>
        <Grid item xs={2} className="card-grid">
          Active
        </Grid>
        <Grid item xs={3} className="card-grid">
          Name
        </Grid>
        <Grid item xs={3} className="card-grid">
          View
        </Grid>
        <Grid item xs={2} className="card-grid">
          Delete
        </Grid>
      </Grid>
      {isloading ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CircularProgress
                size={"4rem"}
                style={{
                  color: "#1c226b",
                  margin: "auto",
                }}
              />
            </Box>
          </Backdrop>
        </>
      ) : (
        <>
          {cards.length > 0 ? (
            <div style={style}>
              {cards.map((card, i) => renderCard(card, i))}
            </div>
          ) : (
            <div style={{ color: "#1c226b", padding: "10px" }}>
              No images, please drop few!
            </div>
          )}
        </>
      )}
    </>
  );
}
