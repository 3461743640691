import React, { useState } from "react";
import "../Styles/Login.css";
import logo from "../Assets/logo.png";
import {
  Grid,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
  Button
} from "@mui/material";
import baseUrl from "../Config/config";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-hot-toast";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useFormik } from "formik";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required*";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address*";
  }
  return errors;
};

export default function ForgetPassword(props) {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      axios
        .post(`${baseUrl.baseUrl}api/forget-password/`, {
          email: formik.values.email,
        })
        .then(function (response) {
          toast.success("Password sent to your Email",{ duration: 1000 });
          props.onGoBack();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const goBackHandler = () => {
    props.onGoBack();
  };

  return (
    <div>
      <form className="logincontainer" onSubmit={formik.handleSubmit}>
        <img src={logo} alt="logoimage" className="logoimage"></img>
        <span
          id="txt1"
          style={{
            font: "1rem secondaryFont, sans-serif",
          }}
        >
          Welcome to P2P
        </span>
        <span
          id="txt1"
          style={{
            font: "1rem secondaryFont, sans-serif",
          }}
        >
          Please enter the below details.
        </span>
        <Grid className="textfields">
          <Grid item style={{ height: "90px" }}>
            <InputLabel htmlFor="demo-customized-textbox" id="inputlabels">
              Enter Your Email
            </InputLabel>
            <TextField
              id="inputboxe1"
              margin="dense"
              size="small"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              autoFocus={true}
              InputProps={{
                style: {
                  backgroundColor: "rgb(232, 240, 254)",
                  color: "black",
                  borderColor: "white",
                  font: "0.8rem secondaryFont, sans-serif",
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ width: "30px", marginRight: "-8px" }}
                  >
                    <IconButton edge="end">
                      {formik.touched.email && formik.errors.email ? (
                        <ErrorOutlineIcon style={{width:"18px",height:"18px",color:"red"}}/>
                      ) : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Button
            className="loginbtn"
            type="submit"
            variant="contained" 
            color="success"
            style={{
              font: "1rem secondaryFont, sans-serif",
              width:"298px"
            }}
          >
            SUBMIT
          </Button>
          <div className="back-to-login">
            <ArrowBackIcon
              fontSize="small"
              style={{
                marginTop: "10px",
                color: "#fff",
                paddingRight: "3px",
              }}
              onClick={goBackHandler}
            />
            <p style={{ fontSize: "12px" }} onClick={goBackHandler}>
              Back to login page
            </p>
          </div>
        </Grid>
      </form>
    </div>
  );
}
