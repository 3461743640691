export function sendcreatedsport(sportsdata) {
  return {
    type: "sendcreatedsport",
    sportsdata
  };
}
export function sendChallengeGraphData(graphData) {
  return {
    type: "sendChallengeGraphData",
    graphData
  };
}
export function sendCreatedUsers(userData) {
  return {
    type: "sendCreatedUsers",
    userData
  };
}
export function sendCreatedMarkets(marketData) {
  return {
    type: "sendCreatedMarkets",
    marketData
  };
}
export function sendIsUserCreated(isCreated) {
  return {
    type: "sendIsUserCreated",
    isCreated
  };
}
export function sendIsUserDeleted(isDeleted) {
  return {
    type: "sendIsUserDeleted",
    isDeleted
  };
}
export function sendIsUserUpdated(isUserUpdated) {
  return {
    type: "sendIsUserUpdated",
    isUserUpdated
  };
}
export function sendIsSportCreated(isSportCreated) {
  return {
    type: "sendIsSportCreated",
    isSportCreated
  };
}
export function sendIsSportDeleted(isSportDeleted) {
  return {
    type: "sendIsSportDeleted",
    isSportDeleted
  };
}
export function sendIsMarketDeleted(isMarketDeleted) {
  return {
    type: "sendIsMarketDeleted",
    isMarketDeleted
  };
}
export function sendIsMarketUpdated(isMarketUpdated) {
  return {
    type: "sendIsMarketUpdated",
    isMarketUpdated
  };
}
export function sendMarketType(marketType) {
  return {
    type: "sendMarketType",
    marketType,
  };
}
export function sendPermissionsData(permissionsData) {
  return {
    type: "sendPermissionsData",
    permissionsData
  };
}
export function getPass(pass) {
  return {
    type: "getPass",
    pass
  };
}
export function getImagelist(isUpdated) {
  return {
    type: "getImagelist",
    isUpdated
  };
}
export function sendIsLoading(isLoading) {
  return {
    type: "sendIsLoading",
    isLoading
  };
}
export function senduserdetails(userinfo){
  return {
    type : "getuserdetails",
    userinfo
  }
}