import React, { useState, useEffect } from "react";
import {
  Paper,
  FormControlLabel,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  Grid,
  Skeleton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "../../Styles/Sports.css";
import baseUrl from "../../Config/config";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../Redux/Action";
import { toast } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import { SocketCall } from "../../utils/WebSockets";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MarketTable = () => {
  const isloading = useSelector((state) => state.isLoading);
  const adminpermissions = useSelector((state) => state.permissionsData);
  const createdMarket = useSelector((state) => state.marketData);
  const dispatch = useDispatch();
  //state variables
  const [rows, setRows] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [activeMarkets, setActiveMarkets] = useState();
  const [type, setType] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [marketList, setMarketList] = useState();
  const [selectedSports, setSelectedSports] = useState([]);
  const UPDATE_API = `${baseUrl.baseUrl}api/market/`;
  const SPORT_LIST = `${baseUrl.baseUrl}api/sports/get_sport/`;
  const websocket = new SocketCall();
  const token = localStorage.getItem("AccessToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let sportsListItem;

  const fetchData = () => {
    axios
      .get(`${baseUrl.baseUrl}api/market/`, { headers })
      .then(function (response) {
        setIsLoaded(true);
        dispatch(Actions.sendMarketType(response.data));
        handleData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchSports = () => {
    axios
      .get(SPORT_LIST, { headers })
      .then(function (response) {
        sportsListItem = response.data.sports;
        setActiveMarkets(response.data.sports);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
    fetchSports();
  }, [createdMarket]);

  const handleData = (data) => {
    const updatedRows = data.map((item) => {
      return {
        _id: item.id,
        active: (
          <FormControlLabel
            sx={{
              display: "block",
            }}
            control={
              <Switch
                checked={item.is_active}
                onChange={() =>
                  adminpermissions && adminpermissions.permission.Market.update
                    ? handleActive(item.id, item.is_active)
                    : toast.error("Access denied", { duration: 1000 })
                }
                name="loading"
                color="primary"
              />
            }
          />
        ),
        type: item.type,
        markettype: item.market_type,
        marketname: item.market_name,
        sports: item.sportsmarket_set
          ? item.sportsmarket_set
              .map((data) => {
                return data.sport.sport_name;
              })
              .join(", ")
          : "",
        updatedon: formatDate(item.updated_on),
        createdon: formatDate(item.created_on),
        actions: (
          <div style={{ display: "flex" }}>
            <Button
              onClick={() =>
                adminpermissions && adminpermissions.permission?.Market?.update
                  ? handleEdit(item.type)
                  : toast.error("Access denied", { duration: 1000 })
              }
            >
              <EditIcon />
            </Button>
            <Button
              disabled={item.is_active === true}
              style={{ color: item.is_active ? "#9e9e9e" : "#f44336" }}
              onClick={() =>
                adminpermissions && adminpermissions.permission?.Market?.delete
                  ? handleConfirm(item.id)
                  : toast.error("Access denied", { duration: 1000 })
              }
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        ),
      };
    });
    setRows(updatedRows);
  };

  const handleEdit = (data) => {
    if (sportsListItem && sportsListItem.length > 0) {
      const market = { type: data };
      const reqApi = {
        command: "request_session",
        params: {
          site_id: 1334,
          language: "en",
        },
      };
      const allSports = {
        command: "get",
        params: {
          source: "betting",
          what: {
            sport: [],
          },
          where: {
            market: market,
            sport: {
              id: {
                "@in": sportsListItem,
              },
            },
          },
        },
      };
      (async () => {
        var response = await websocket.sendcommand(allSports);
        const sportsData =
          response &&
          response.data &&
          response.data.data &&
          response.data.data.sport;

        if (sportsData) {
          const sportsArr = Object.values(sportsData);
          const allSports = sportsArr.map((item) => ({
            id: item.id,
            name: item.name,
          }));
          setMarketList(allSports);
        }
      })();
    }
    setType(data);
    setEditOpen(true);
  };

  const handleActive = (_id, is_active) => {
    const is_active_val = !is_active;
    // setActiveData({ ...activeData, [_id]: !is_active });

    const activeData = { id: _id, is_active: is_active_val };
    axios
      .put(`${baseUrl.baseUrl}api/market/is_active/`, activeData, { headers })
      .then(function (response) {
        fetchData();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = (_id) => {
    dispatch(Actions.sendIsLoading(true));
    const deleteData = { id: _id, is_delete: true };
    axios
      .put(`${baseUrl.baseUrl}api/market/is_delete/`, deleteData, { headers })
      .then(function (response) {
        // update the data in the state if the delete was successful
        // setRows((prevRows) => prevRows.filter((row) => row._id !== _id));
        dispatch(Actions.sendIsLoading(false));
        fetchData();
        handleClose();
        dispatch(Actions.sendIsMarketDeleted(true));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleConfirm = (id) => {
    setId(id);
    setOpen(true);
  };

  const formatDate = (val) => {
    const dateVal = new Date(val);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = dateVal.toLocaleDateString("en-GB", options);
    const formattedTime = dateVal.toLocaleTimeString();
    return `${formattedDate},${formattedTime}`;
  };

  //Table content
  const columns = [
    { id: "_id", label: "Id" },
    { id: "active", label: "Active" },
    { id: "type", label: "Type" },
    {
      id: "markettype",
      label: "Market Type",
    },
    {
      id: "marketname",
      label: "Market Name",
    },
    {
      id: "sports",
      label: "Sports",
    },
    {
      id: "updatedon",
      label: "Updated on",
    },
    {
      id: "createdon",
      label: "Created on",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const handleSportsList = (event) => {
    setSelectedSports(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(Actions.sendIsLoading(true));
    const data = { sport: selectedSports, type: type };
    axios
      .put(UPDATE_API, data, { headers })
      .then(function (response) {
        dispatch(Actions.sendIsLoading(false));
        handleEditClose();
        dispatch(Actions.sendIsMarketUpdated(true));
        fetchData();
        fetchSports();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClose = () => {
    setSelectedSports([]);
    // setSportList(null);
    setEditOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {" "}
      {isLoaded ? (
        <div className="table-sports">
          <Paper
            sx={{
              width: "100%",
              backgroundColour: "black",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center" id="table-row">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No records found
                      </TableCell>
                    </TableRow>
                  )}
                  {rows &&
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  style={{ padding: "9px" }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                  style={{
                    textAlign: "center",
                    color: "#1c226b",
                    fontWeight: "bolder",
                  }}
                >
                  <p>Are you sure you want to delete this item ?</p>
                </DialogTitle>
                <DialogContent
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px" }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDelete(id)}
                  >
                    Confirm
                  </Button>
                </DialogContent>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isloading && isloading}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CircularProgress
                      size={"4rem"}
                      style={{
                        color: "#1c226b",
                        margin: "auto",
                      }}
                    />
                  </Box>
                </Backdrop>
              </Dialog>
            </div>
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={editOpen}
              onClose={handleEditClose}
            >
              <Grid container style={{ justifyContent: "end", padding: "5px" }}>
                <CloseIcon
                  onClick={() => handleEditClose()}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
              <DialogContent>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Type"
                    size="small"
                    defaultValue={type}
                  />
                  <FormControl
                    style={{ width: "100%", marginLeft: "10px" }}
                    size="small"
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Sports Name
                    </InputLabel>
                    <Select
                      style={{ width: "100%", margin: 0, height: "100%" }}
                      labelId="demo-multiple-checkbox-label"
                      id="sports"
                      size="small"
                      multiple
                      value={selectedSports}
                      onChange={handleSportsList}
                      input={<OutlinedInput label="MarketType" />}
                      MenuProps={MenuProps}
                    >
                      {marketList &&
                        marketList.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.id} {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <Grid
                container
                style={{ justifyContent: "center", marginBottom: "30px" }}
              >
                <Grid item xs={6}>
                  <Button
                    className="submit-markbtn"
                    variant="contained"
                    style={{
                      width: "100%",
                      backgroundColor: "#1c226b",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isloading && isloading}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CircularProgress
                    size={"4rem"}
                    style={{
                      color: "#1c226b",
                      margin: "auto",
                    }}
                  />
                </Box>
              </Backdrop>
            </Dialog>
          </Paper>
        </div>
      ) : (
        <div className="table-sports">
          <Paper
            sx={{
              width: "100%",
              backgroundColour: "black",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table aria-label="table skeleton">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center" id="table-row">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      {[...Array(9)].map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  );
};

export default MarketTable;
