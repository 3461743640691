import React, { useState, useEffect } from "react";
import UserManagementModal from "../Components/UserManagementComponents/UserManagementModal";
import NavBar from "../Components/NavBar";
import { Button, Dialog } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UserManagementTable from "../Components/UserManagementComponents/UserManagementTable";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import * as Actions from "../Redux/Action";
import { toast } from "react-hot-toast";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserManagement() {
  const adminpermissions = useSelector((state) => state.permissionsData);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const isCreatedUser = useSelector((state) => state.isCreated);
  const isDeletedUser = useSelector((state) => state.isDeleted);
  const isUpdatedUser = useSelector((state) => state.isUserUpdated);

  const handleAddNew = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isCreatedUser) {
      toast.success("Successfully Created",{ duration: 1000 });
      dispatch(Actions.sendIsUserCreated(false));
    }
    if (isDeletedUser) {
      toast.success("Successfully Deleted",{ duration: 1000 });
      dispatch(Actions.sendIsUserDeleted(false));
    }
    if (isUpdatedUser) {
      toast.success("Successfully Updated",{ duration: 1000 });
      dispatch(Actions.sendIsUserUpdated(false));
    }
  }, [isCreatedUser, isDeletedUser, isUpdatedUser]);

  return (
    <div>
      <NavBar />
      <div>
        <div>
          <h2 id="headingtxt">User Management</h2>
        </div>
        <Button
          variant="outlined"
          style={{
            margin: "0px 20px 10px",
            color: "#1c226b",
            textTransform: "capitalize",
            float: "left",
            marginLeft: "10px",
            borderRadius:"5px"
          }}
          onClick={() =>
            adminpermissions &&
            adminpermissions.permission?.UserManagement?.create
              ? handleAddNew()
              : toast.error("Access denied",{ duration: 1000 })
          }
        >
          <AddIcon />
          &nbsp;&nbsp;Add New
        </Button>
        <div>
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={open}
            onClose={handleClose}
          >
            <div>
              {userData && (
                <UserManagementModal
                  onClose={handleClose}
                  userData={userData}
                />
              )}
            </div>
          </Dialog>
        </div>
        <UserManagementTable />
      </div>
    </div>
  );
}
