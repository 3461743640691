import * as React from "react";
import { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";
import "../../Styles/filters.css";
import ReportsFilterTable from "../ReportsComponents/ReportsFiltertable";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Papa from "papaparse";
import baseUrl from "../../Config/config";
import { saveAs } from "file-saver";
import moment from "moment";
import axios from "axios";
import * as Actions from "../../Redux/Action";
import { useSelector , useDispatch } from "react-redux";

const now = new Date();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const challengemodes = ["Public", "Private"];
const Winner = ["Won", "Lost", "Draw"];

export default function Reportsfilters() {
  const [challengeType, setChallengeType] = useState([]);
  const [winnerType, setWinnerType] = useState();
  const [usertype, setusertype] = React.useState();
  const [isExport,setIsExport] = useState(false)
  const [response, setResponse] = useState([]);
  const [exportDatas, setExportDatas] = useState();
  const [challengeBody, setChallengeBody] = React.useState([]);
  const [csv, setCsv] = useState();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const token = localStorage.getItem("AccessToken");
  const today = new Date();
  const dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  function convertJsonToCsv(jsonData) {
    const csv = Papa.unparse(jsonData);
    return csv;
  }

  function downloadCsv(csvData, fileName) {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, fileName);
  }

  const onExport = () => {
    downloadCsv(csv, "data.csv");
  };

  const handlechallengetype = (event) => {
    if (event.target.value.length === 1) {
      setChallengeType(event.target.value);
      setChallengeBody(event.target.value[0])
    } else {
      setChallengeType(event.target.value);
      setChallengeBody([]);
    }
    // setChallengeType(event.target.value);
  };
  const handleWinnerType = (event) => {
    setWinnerType(event.target.value);
  };

  const handleusertype = (event) => {
    setusertype(event.target.value);
    setWinnerType(Winner); // enable the winner type field
  };

  const handleSubmit = () => {
    dispatch(Actions.sendIsLoading(true));
    const filterData = {
      challenge_type: challengeBody,
      winner: winnerType,
      user_id: usertype,
      start_date: startDate,
      end_date: endDate,
    };
    axios
      .put(`${baseUrl.baseUrl}api/reports/`, filterData, { headers })
      .then(function (response) {
        dispatch(Actions.sendIsLoading(false));
        setResponse(response.data.results);
        const exportData = response.data.results.map((item) => ({
          id:item.id,
          challenge: item.challenge,
          created_user: item.created_user,
          selected_by:item.selected_by,
          winner: item.winner,
          status: item.status,
          group_name:item.group_name,
          type_of_challenge: item.type_of_challenge,
          created_at:formatDate(item.created_at),
          sport_id:item.sport_id,
          sport_name: item.sport_name,
          region_id:item.region_id,
          competition_id:item.competition_id,
          game_name: item.game_name,
          game_id:item.game_id,
          market_name: item.market_name,
          market_id:item.market_id,
          event_name: item.event_name,
          event_id:item.event_id,
          stake_amount: item.stack_amount,
          settlement_amount:item.settlement_amount,
          game_date:formatGameDate(item.game_date),
          game_time:item.game_time,
          game_timestamp:item.game_timestamp,
          result_timestamp:item.result_timestamp,
          settled:item.settled,
          market_settlement_time:formatDate(item.market_settlement_time),
          result: item.result,
          match_wining_amount: item.match_wining_amount,
          tax: item.tax,
        }));
        setExportDatas(exportData);
        const csvData = convertJsonToCsv(exportData);
        setCsv(csvData);
        setIsExport(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formatDate = (val) => {
    const dateVal = new Date(val);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = dateVal.toLocaleDateString('en-GB',options);
    const formattedTime = dateVal.toLocaleTimeString();
    return `${formattedDate},${formattedTime}`;
  };

  const formatGameDate=(val)=>{
    const dateVal = new Date(val);
    const options = {day:"2-digit",month:"2-digit",year:"numeric"};
    const formattedDate = dateVal.toLocaleDateString("en-GB",options);
    return `${formattedDate}`;
  }

  return (
    <div className="filterscontainer">
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        <Grid item md={2}>
          <FormControl
            style={{ width: "100%", textAlign: "left" }}
            size="small"
          >
            <div className="filterfields">
              <InputLabel id="label1">Challenge Type</InputLabel>
              <Select
                labelId="challengetype"
                id="challengetype"
                style={{ margin: 0, width: "100%" }}
                value={challengeType}
                onChange={handlechallengetype}
                input={<OutlinedInput label="ChallengeType" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                multiple
              >
                {/* {challengemodes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))} */}
                {challengemodes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={challengeType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                    {/* {type} */}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl style={{ width: "100%" }} size="small">
            <TextField
              id="outlined-basic"
              label="User Id"
              variant="outlined"
              size="small"
              type="number"
              onChange={handleusertype}
            />
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl
            style={{ width: "100%", textAlign: "left" }}
            size="small"
          >
            <div className="filterfields">
              <InputLabel id="label1">Winner Type</InputLabel>
              <Select
                labelId="winnertype"
                id="winnertype"
                style={{ margin: 0, width: "100%" }}
                value={winnerType}
                onChange={handleWinnerType}
                input={<OutlinedInput label="Winner Type" />}
                MenuProps={MenuProps}
                disabled={!usertype} // disable until user id is entered
              >
                {Winner.map((type, index) => (
                  <MenuItem key={type} value={index + 1}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </FormControl>
        </Grid>

        <Grid item md={2}>
          <FormControl style={{ width: "100%" }} size="small">
            <TextField
              defaultValue={moment(today).format("YYYY-MM-DD")}
              inputProps={{
                max: today.toISOString().slice(0, 10),
              }}
              required
              variant="outlined"
              label="Start Date"
              type="date"
              size="small"
              id="textField"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: 0, width: "100%" }}
              onChange={(e) => {
                setStartDate(e.target.value);
                setEndDate(moment(today).format("YYYY-MM-DD"));
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl style={{ width: "100%" }} size="small">
            <TextField
              // defaultValue={moment().format("YYYY-MM-DD")}
              value={endDate}
              inputProps={{
                min: startDate,
                max: today.toISOString().slice(0, 10),
              }}
              required
              variant="outlined"
              label="End Date"
              type="date"
              id="textField"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: 0, width: "100%" }}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ margin: "15px 0px 30px 0px", justifyContent: "center" }}
      >
        <Grid item md={2} style={{ display: "inherit", alignItems: "center" }}>
          <Button
            style={{ backgroundColor: "#1c226b", width: "100%", height: "90%" }}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Apply Filter
          </Button>
        </Grid>
        {isExport && <Grid item md={2} style={{ display: "inherit", alignItems: "center" }}>
          <Button
            style={{ backgroundColor: "#1c226b", width: "100%", height: "90%" }}
            variant="contained"
            onClick={() => onExport()}
          >
          <FileDownloadOutlinedIcon style={{paddingRight:"3px" , height:"19px",width:"19px",}}/>
            Export
          </Button>
        </Grid>}
      </Grid>

      {response && (
        <div className="tabelcontainer" style={{ margin: "10px 0px",width:"100%" }}>
          <ReportsFilterTable data={response} />
        </div>
      )}
    </div>
  );
}
