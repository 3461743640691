import React, { useState, useEffect } from "react";
import ChallengeGraph from "../Components/ChallengeComponents/ChallengeGraphs";
import Challengespie from "../Components/ChallengeComponents/ChallengesPie";
import NavBar from "../Components/NavBar";
import Dashboardfilters from "../Components/DashboardComponents/Dashboardfilters";
import { Grid, Skeleton } from "@mui/material";
import ChallengeTable from "../Components/ChallengeComponents/ChallengeTable";
import LoadingSpinner from "../Components/LoadingSpinner";
import ChallengeTypePie from "../Components/ChallengeComponents/ChallengeTypePie";
import UssdWebPie from "../Components/ChallengeComponents/UssdWebPie";
import baseUrl from "../Config/config";
import moment from "moment";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export default function Dashboard(props) {
  const [responseData, setResponseData] = useState();
  const token = localStorage.getItem("AccessToken");
  const FILTER_URL = `${baseUrl.baseUrl}api/challenges-info/`;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const filterData = {
      user_id: "",
      challenge_type: "",
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };
    axios
      .post(FILTER_URL, filterData, { headers })
      .then(function (response) {
        setResponseData(response.data);
        // const data = [
        //   ...Object.entries(response.data.chart).map(([day, density]) => [
        //     day,
        //     density
        //   ]),
        // ];
        // setGraphData(response.data.chart);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <NavBar />
      {responseData ? (
        <div className="body-container" style={{ margin: "30px" }}>
          <Dashboardfilters />
          <Grid item xs={12} style={{ margin: "30px 0px 20px 0px" }}>
            <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}></hr>
          </Grid>
          <Grid container spacing={3} style={{ paddingBottom: "24px" }}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <ChallengeTable data={responseData} />
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <ChallengeGraph data={responseData.chart} />
            </Grid>
            <Grid item xs={12} style={{ margin: "0px 0px 0px 0px" }}>
              <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}></hr>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Challengespie data={responseData} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <ChallengeTypePie data={responseData} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <UssdWebPie data={responseData} />
            </Grid>
          </Grid>
        </div>
      ) : (
        // <>
        //   <LoadingSpinner />

        // </>
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CircularProgress
                size={"4rem"}
                style={{
                  color: "#1c226b",
                  margin: "auto",
                }}
              />
            </Box>
          </Backdrop>
        </>
      )}
    </div>
  );
}
