import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/NavBar.css";
import logo from "../Assets/logo.png";
import { Dialog } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import SummarizeIcon from "@mui/icons-material/Summarize";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsIcon from "@mui/icons-material/Settings";
import { toast } from "react-hot-toast";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ChangePassword from "../Pages/ChangePassword";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";

const icons = [
  "DashboardIcon",
  "SportsSoccerIcon",
  "LocalGroceryStoreIcon",
  "MilitaryTechIcon",
  "DashboardIcon",
];
const NavBar = () => {
  const navigate = useNavigate();
  const adminpermissions = useSelector((state) => state.permissionsData);
  const userdetails = useSelector((state) => state.userinfo);

  const pages = [
    {
      title: "Dashboard",
      url: "/Admin/Dashboard",
      icon: <DashboardIcon style={{ height: "16px", paddingRight: "2px" }} />,
    },
    {
      title: "Sports",
      url: "/Admin/Sports",
      icon: (
        <SportsSoccerIcon style={{ height: "16px", paddingRight: "2px" }} />
      ),
    },
    {
      title: "Markets",
      url: "/Admin/Markets",
      icon: (
        <LocalGroceryStoreIcon
          style={{ height: "16px", paddingRight: "2px" }}
        />
      ),
    },
    // { title: 'Competition', url: '/Admin/Competition', },
    // { title: 'ChallengeMaster', url: '/Admin/ChallengeMaster', icon:<MilitaryTechIcon style={{height:"16px",paddingRight:"2px"}}/> },
    {
      title: "Refund",
      url: "/Admin/Refund",
      icon: (
        <AccountBalanceWalletIcon
          style={{ height: "16px", paddingRight: "2px" }}
        />
      ),
    },
    {
      title: "Reports",
      url: "/Admin/Reports",
      icon: <SummarizeIcon style={{ height: "16px", paddingRight: "2px" }} />,
    },
    {
      title: "User Management",
      url: "/Admin/UserManagement",
      icon: <GroupIcon style={{ height: "16px", paddingRight: "2px" }} />,
    },
    {
      title: "Configure",
      url: "/Admin/Configure",
      icon: <SettingsIcon style={{ height: "16px", paddingRight: "2px" }} />,
    },
    {
      title: "Carousel Images",
      url: "/Admin/CarouselImages",
      icon: (
        <AddPhotoAlternateIcon
          style={{ height: "16px", paddingRight: "2px" }}
        />
      ),
    },
  ];

  // const settings = [
  //     { title: 'Profile', url: '' },
  //     { title: 'logout', url: '' },];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const Logout = () => {
    navigate("/");
    // localStorage.removeItem("AccessToken");
    // localStorage.removeItem("userpermissions");
    // toast.success("Logged out successfully", { duration: 1000 });
  };
  const changePasswordHandler = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (adminpermissions && adminpermissions.user_id?.new_login) {
      setTimeout(() => {
        setOpen(true);
      }, 5000);
    }
  }, [adminpermissions]);

  return (
    <>
      <AppBar
        position="static"
        style={{ boxShadow: "0 5px 10px 5px #888", backgroundColor: "#1c226b" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              id="logo"
              src={logo}
              alt="CRG-p2p-admin"
              style={{ height: "65px", width: "65px" }}
            />
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, i) => (
                  <MenuItem key={i} onClick={handleCloseNavMenu}>
                    <Link
                      to={page.url}
                      className="pages-btn"
                      style={{ color: "white" }}
                    >
                      {page.title}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, i) => (
                <Button
                  key={page.title}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    padding: "2px",
                  }}
                >
                  <Link
                    to={page.url}
                    key={i}
                    className="pages"
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {page.icon}
                    {page.title}
                  </Link>
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircleIcon
                    style={{ color: "#fff", width: "40px", height: "30px" }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px", alignItems: "center" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <div style={{ fontWeight: "bold" }}>
                  {userdetails && "Hi," + " " + userdetails.first_name}
                </div>
                <MenuItem className="logoutbtn" onClick={changePasswordHandler}>
                  <SettingsIcon style={{ paddingRight: "10px" }} />
                  Change Password
                </MenuItem>
                <MenuItem className="logoutbtn" onClick={Logout}>
                  <LogoutIcon
                    style={{ cursor: "pointer", paddingRight: "10px" }}
                  />
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <ChangePassword
            onClose={handleClose}
            newuser={
              adminpermissions && adminpermissions.user_id?.new_login
                ? true
                : false
            }
          />
        </Dialog>
      </div>
    </>
  );
};

export default NavBar;
