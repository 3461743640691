import { Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import Dashboard from "../Pages/Dashboard";
import Markets from "../Pages/Markets";
import Reports from "../Pages/Reports";
import Sports from "../Pages/Sports";
import UserManagement from "../Pages/UserManagement";
import CarouselImages from "../Pages/CarouselImages";
import RefundUsers from "../Pages/RefundUsers";
import Configure from "../Pages/Configure";
import { useSelector } from "react-redux";
import RedirectRoutes from './RedirectRoute';
import AccessDenied from "../Pages/AccessDenied";
import baseUrl from "../Config/config";
import React from "react";
import axios from "axios";

const AdminPrivateRoutes = () => {
  const adminpermissions = useSelector((state) => state.permissionsData);
  const token = localStorage.getItem("AccessToken");
  const [initialValue, setInitialValue] = useState({})
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("isLoggedIn");
    setIsLoggedIn(loggedIn === "true");
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      sessionStorage.setItem("isLoggedIn", isLoggedIn);
    };
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("unload", handleUnload);
    };
  }, [isLoggedIn]);


  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/Dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />} />
      <Route path="/Markets" element={adminpermissions && adminpermissions.permission && adminpermissions.permission.Market.view ? <Markets /> : (<Navigate to="/Admin/404/Accessdenied" />)} />
      <Route path="/Sports" element={adminpermissions && adminpermissions.permission && adminpermissions.permission.Sport.view ? <Sports /> : (<Navigate to="/Admin/404/Accessdenied" />)} />
      <Route path="/Reports" element={adminpermissions && adminpermissions.permission && adminpermissions.permission.Reports.view ? <Reports /> : (<Navigate to="/Admin/404/Accessdenied" />)} />
      <Route path="/UserManagement" element={adminpermissions && adminpermissions.permission && adminpermissions.permission.UserManagement.view ? <UserManagement /> : (<Navigate to="/Admin/404/Accessdenied" />)} />
      <Route path="/Refund" element={adminpermissions && adminpermissions.permission && adminpermissions.permission.Refund.view ? <RefundUsers /> : (<Navigate to="/Admin/404/Accessdenied" />)} />
      <Route path="/Configure" element={adminpermissions && adminpermissions.permission && adminpermissions.permission.Configure.view ? <Configure /> : (<Navigate to="/Admin/404/Accessdenied" />)} />
      <Route path="/CarouselImages" element={adminpermissions && adminpermissions.permission && adminpermissions.permission.CarouselImages.view ? <CarouselImages /> : (<Navigate to="/Admin/404/Accessdenied" />)} />
      <Route path="/404/Accessdenied" element={<AccessDenied />} />
    </Routes>
  );
};

export default AdminPrivateRoutes;
