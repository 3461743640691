import React, { useEffect, useState } from "react";
import {
  DialogContent,
  TextField,
  Button,
  Grid,
  Checkbox,
} from "@mui/material";
import "../../Styles/Sports.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SecurityIcon from "@mui/icons-material/Security";
import baseUrl from "../../Config/config";
import Paper from "@mui/material/Paper";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import * as Actions from "../../Redux/Action";
import "../../Styles/UserManagement.css";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

export default function UserManagementModal(props) {
  const isloading = useSelector((state) => state.isLoading);
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const [response, setResponse] = useState([]);
  const [accessMngmntOpen, setAccessMngmntOpen] = useState(true);

  const [marketView, setmarketView] = useState();
  const [marketCreate, setmarketCreate] = useState();
  const [marketUpdate, setmarketUpdate] = useState();
  const [marketDelete, setmarketDelete] = useState();

  const [refundView, setrefundView] = useState();
  const [refundCreate, setrefundCreate] = useState();
  const [refundUpdate, setrefundUpdate] = useState();
  const [refundDelete, setrefundDelete] = useState();

  const [sportView, setsportView] = useState();
  const [sportCreate, setsportCreate] = useState();
  const [sportUpdate, setsportUpdate] = useState();
  const [sportDelete, setsportDelete] = useState();

  const [configureView, setconfigureView] = useState();
  const [configureCreate, setconfigureCreate] = useState();
  const [configureUpdate, setconfigureUpdate] = useState();
  const [configureDelete, setconfigureDelete] = useState();

  const [usermanagementView, setusermanagementView] = useState();
  const [usermanagementCreate, setusermanagementCreate] = useState();
  const [usermanagementUpdate, setusermanagementUpdate] = useState();
  const [usermanagementDelete, setusermanagementDelete] = useState();

  const [reportsView, setreportsView] = useState();
  const [reportsCreate, setreportsCreate] = useState();
  const [reportsUpdate, setreportsUpdate] = useState();
  const [reportsDelete, setreportsDelete] = useState();

  const [CarouselImageView, setcarouselImageView] = useState();
  const [CarouselImageCreate, setcarouselImageCreate] = useState();
  const [CarouselImageUpdate, setcarouselImageUpdate] = useState();
  const [CarouselImageDelete, setcarouselImageDelete] = useState();

  const dispatch = useDispatch();
  const token = localStorage.getItem("AccessToken");
  const ADMIN_PERM = `${baseUrl.baseUrl}api/admin-permission/?id=${props.id}`;
  const UPDATE_API = `${baseUrl.baseUrl}api/admin-permission/`;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = () => {
    axios
      .get(ADMIN_PERM, { headers })
      .then(function (response) {
        updateAllState(response.data.results);
        setResponse(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateAllState = (data) => {
    setmarketView(data[0].permission.Market.view);
    setmarketCreate(data[0].permission.Market.create);
    setmarketUpdate(data[0].permission.Market.update);
    setmarketDelete(data[0].permission.Market.delete);

    setrefundView(data[0].permission.Refund.view);
    setrefundCreate(data[0].permission.Refund.create);
    setrefundUpdate(data[0].permission.Refund.update);
    setrefundDelete(data[0].permission.Refund.delete);

    setsportView(data[0].permission.Sport.view);
    setsportCreate(data[0].permission.Sport.create);
    setsportUpdate(data[0].permission.Sport.update);
    setsportDelete(data[0].permission.Sport.delete);

    setconfigureView(data[0].permission.Configure.view);
    setconfigureCreate(data[0].permission.Configure.create);
    setconfigureUpdate(data[0].permission.Configure.update);
    setconfigureDelete(data[0].permission.Configure.delete);

    setusermanagementView(data[0].permission.UserManagement.view);
    setusermanagementCreate(data[0].permission.UserManagement.create);
    setusermanagementUpdate(data[0].permission.UserManagement.update);
    setusermanagementDelete(data[0].permission.UserManagement.delete);

    setreportsView(data[0].permission.Reports.view);
    setreportsCreate(data[0].permission.Reports.create);
    setreportsUpdate(data[0].permission.Reports.update);
    setreportsDelete(data[0].permission.Reports.delete);

    setcarouselImageView(data[0].permission.CarouselImages.view);
    setcarouselImageCreate(data[0].permission.CarouselImages.create);
    setcarouselImageUpdate(data[0].permission.CarouselImages.update);
    setcarouselImageDelete(data[0].permission.CarouselImages.delete);
  };

  const onSubmitHandler = () => {
    dispatch(Actions.sendIsLoading(true));
    const updateData = {
      id: props.id,
      permission: {
        Sport: {
          create: sportCreate,
          view: sportView,
          delete: sportDelete,
          update: sportUpdate,
        },
        Market: {
          create: marketCreate,
          view: marketView,
          delete: marketDelete,
          update: marketUpdate,
        },
        Refund: {
          create: refundCreate,
          view: refundView,
          delete: refundDelete,
          update: refundUpdate,
        },
        UserManagement: {
          create: usermanagementCreate,
          view: usermanagementView,
          delete: usermanagementDelete,
          update: usermanagementUpdate,
        },
        Configure: {
          create: configureCreate,
          view: configureView,
          delete: configureDelete,
          update: configureUpdate,
        },
        Reports: {
          create: reportsCreate,
          view: reportsView,
          delete: reportsDelete,
          update: reportsUpdate,
        },
        CarouselImages: {
          view: CarouselImageView,
          create: CarouselImageCreate,
          update: CarouselImageUpdate,
          delete: CarouselImageDelete,
        },
      },
    };

    axios
      .put(UPDATE_API, updateData, { headers })
      .then(function (response) {
        props.onClose();
        dispatch(Actions.sendIsLoading(false));
        fetchData();
        dispatch(Actions.sendIsUserUpdated(true));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleUserInfo = () => {
    const element = document.getElementById("access_mngemnt");
    element.classList.add("disable_button");
    const element1 = document.getElementById("user_infor");
    element1.classList.remove("disable_button");
    setUserInfoOpen(true);
    setAccessMngmntOpen(false);
  };

  const handleAccessMngmnt = () => {
    const element = document.getElementById("access_mngemnt");
    element.classList.remove("disable_button");
    const element1 = document.getElementById("user_infor");
    element1.classList.add("disable_button");
    setAccessMngmntOpen(true);
    setUserInfoOpen(false);
  };

  const handleUpdateClose = () => {
    props.onClose();
  };

  return (
    <>
      {response[0] && (
        <div>
          <Grid container style={{ justifyContent: "end", padding: "5px" }}>
            <CloseIcon
              onClick={() => handleUpdateClose()}
              style={{ cursor: "pointer" }}
            />
          </Grid>
          <DialogContent style={{ paddingTop: "10px" }}>
            <Grid container style={{ marginBottom: "10px" }}>
              <Grid item xs={6} style={{ paddingRight: "3px" }}>
                <Button
                  id="user_infor"
                  className="disable_button"
                  style={{
                    backgroundColor: "#1c226b",
                    width: "100%",
                    height: "100%",
                    borderRadius: "0px",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  onClick={() => handleUserInfo()}
                >
                  <PersonAddAltIcon style={{ marginRight: "5px" }} /> User
                  Information
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  id="access_mngemnt"
                  style={{
                    backgroundColor: "#1c226b",
                    width: "100%",
                    height: "100%",
                    color: "#fff",
                    borderRadius: "0px",
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  onClick={() => handleAccessMngmnt()}
                >
                  <SecurityIcon style={{ marginRight: "1px" }} />
                  Access Management
                </Button>
              </Grid>
            </Grid>
            {accessMngmntOpen && (
              <div>
                <TableContainer
                  component={Paper}
                  style={{ marginTop: "20px", maxWidth: "100%" }}
                >
                  <Table
                    sx={{ minWidth: 535, overflowX: "hidden" }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Module</TableCell>
                        <TableCell align="right">View</TableCell>
                        <TableCell align="right">Create</TableCell>
                        <TableCell align="right">Update</TableCell>
                        <TableCell align="right">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          Sports
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={sportView}
                            onChange={(e) => setsportView(!sportView)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={sportCreate}
                            onChange={(e) => setsportCreate(!sportCreate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={sportUpdate}
                            onChange={(e) => setsportUpdate(!sportUpdate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={sportDelete}
                            onChange={(e) => setsportDelete(!sportDelete)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          Markets
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={marketView}
                            onChange={(e) => setmarketView(!marketView)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={marketCreate}
                            onChange={(e) => setmarketCreate(!marketCreate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={marketUpdate}
                            onChange={(e) => setmarketUpdate(!marketUpdate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={marketDelete}
                            onChange={(e) => setmarketDelete(!marketDelete)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          Refund
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={refundView}
                            onChange={(e) => setrefundView(!refundView)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={refundCreate}
                            onChange={(e) => setrefundCreate(!refundCreate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={refundUpdate}
                            onChange={(e) => setrefundUpdate(!refundUpdate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={refundDelete}
                            onChange={(e) => setrefundDelete(!refundDelete)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          Reports
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={reportsView}
                            onChange={(e) => setreportsView(!reportsView)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={reportsCreate}
                            onChange={(e) => setreportsCreate(!reportsCreate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={reportsUpdate}
                            onChange={(e) => setreportsUpdate(!reportsUpdate)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={reportsDelete}
                            onChange={(e) => setreportsDelete(!reportsDelete)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          User Management
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={usermanagementView}
                            onChange={(e) =>
                              setusermanagementView(!usermanagementView)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={usermanagementCreate}
                            onChange={(e) =>
                              setusermanagementCreate(!usermanagementCreate)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={usermanagementUpdate}
                            onChange={(e) =>
                              setusermanagementUpdate(!usermanagementUpdate)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={usermanagementDelete}
                            onChange={(e) =>
                              setusermanagementDelete(!usermanagementDelete)
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          Configure
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={configureView}
                            onChange={(e) => setconfigureView(!configureView)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={configureCreate}
                            onChange={(e) =>
                              setconfigureCreate(!configureCreate)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={configureUpdate}
                            onChange={(e) =>
                              setconfigureUpdate(!configureUpdate)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={configureDelete}
                            onChange={(e) =>
                              setconfigureDelete(!configureDelete)
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          Carousel Images
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={CarouselImageView}
                            onChange={(e) =>
                              setcarouselImageView(!CarouselImageView)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={CarouselImageCreate}
                            onChange={(e) =>
                              setcarouselImageCreate(!CarouselImageCreate)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={CarouselImageUpdate}
                            onChange={(e) =>
                              setcarouselImageUpdate(!CarouselImageUpdate)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={CarouselImageDelete}
                            onChange={(e) =>
                              setcarouselImageDelete(!CarouselImageDelete)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  style={{ justifyContent: "center", marginTop: "10px" }}
                >
                  <Grid item xs={2} style={{ paddingRight: "2px" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        textTransform: "capitalize",
                        backgroundColor: "#1c226b",
                        width: "100%",
                      }}
                      onClick={() => onSubmitHandler()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
            {userInfoOpen && (
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      id="standard-disabled"
                      label="First Name"
                      defaultValue={response && response[0].user_id.first_name}
                      variant="standard"
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      id="standard-disabled"
                      label="Last Name"
                      defaultValue={response && response[0].user_id.last_name}
                      variant="standard"
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      id="standard-disabled"
                      label="Email"
                      defaultValue={response && response[0].user_id.email}
                      variant="standard"
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ justifyContent: "end", marginTop: "10px" }}
                >
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => handleAccessMngmnt()}
                      style={{
                        textTransform: "capitalize",
                        backgroundColor: "#fff",
                        color: "#1c226b",
                        border: "1px solid #1c226b",
                        width: "100%",
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
          </DialogContent>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={isloading && isloading}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CircularProgress
                size={"4rem"}
                style={{
                  color: "#1c226b",
                  margin: "auto",
                }}
              />
            </Box>
          </Backdrop>
        </div>
      )}
    </>
  );
}
