import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RedirectRoutes = ({children, redirect}) => {
    const adminpermissions = useSelector((state) => state.permissionsData);
    const access = adminpermissions?.permission?.Dashboard?.view ;
    return access ? children : <Navigate to={redirect} />;
}

export default RedirectRoutes;
