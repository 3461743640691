var CryptoJS = require("crypto-js");
const permissions = localStorage.getItem("permissions");
const decryptpermissions =
  permissions && CryptoJS.AES.decrypt(permissions, "my-secret-key@123");
const userpermissions =
  decryptpermissions &&
  JSON.parse(decryptpermissions.toString(CryptoJS.enc.Utf8));
const getpass = localStorage.getItem("pass");
// console.log("pass", getpass);
const decryptpass =
  getpass && CryptoJS.AES.decrypt(getpass, "my-secret-key@123");
const password =
  decryptpass && JSON.parse(decryptpass.toString(CryptoJS.enc.Utf8));
// console.log("password", password);

const allstates = {
  newAddedSport: {},
  permissionsData: userpermissions,
  getImagelist: "",
  pass: password,
  isLoading: false,
};

export default function Reducer(state = allstates, actions) {
  switch (actions.type) {
    case "sendcreatedsport":
      return { ...state, sportsData: actions.sportsdata };
    case "sendChallengeGraphData":
      return { ...state, graphData: actions.graphData };
    case "sendCreatedUsers":
      return { ...state, userData: actions.userData };
    case "sendCreatedMarkets":
      return { ...state, marketData: actions.marketData };
    case "sendIsUserCreated":
      return { ...state, isCreated: actions.isCreated };
    case "sendIsUserDeleted":
      return { ...state, isDeleted: actions.isDeleted };
    case "sendIsUserUpdated":
      return { ...state, isUserUpdated: actions.isUserUpdated };
    case "sendIsSportCreated":
      return { ...state, isSportCreated: actions.isSportCreated };
    case "sendIsSportDeleted":
      return { ...state, isSportDeleted: actions.isSportDeleted };
    case "sendIsMarketDeleted":
      return { ...state, isMarketDeleted: actions.isMarketDeleted };
    case "sendIsMarketUpdated":
      return { ...state, isMarketUpdated: actions.isMarketUpdated };
    case "sendMarketType":
      return { ...state, marketType: actions.marketType };
    case "sendPermissionsData":
      return { ...state, permissionsData: actions.permissionsData };
    case "getPass":
      return { ...state, pass: actions.pass };
    case "getImagelist":
      return { ...state, getImagelist: actions.isUpdated };
    case "sendIsLoading":
      return { ...state, isLoading: actions.isLoading };
    case "getuserdetails":
      return { ...state, userinfo: actions.userinfo };
    default:
      return state;
  }
}
