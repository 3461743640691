import * as React from "react";
import { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Button, TextField, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import * as Actions from "../../Redux/Action";
import baseUrl from "../../Config/config";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import axios from "axios";
import "../../Styles/Dashboard.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const challengemodes = ["Public", "Private"];

export default function Dashboardfilters() {
  const isloading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [usertype, setusertype] = React.useState([]);
  const [challengetype, setchallengetype] = React.useState([]);
  const [challengeBody, setChallengeBody] = React.useState([]);
  const [response, setResponse] = useState();
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const token = localStorage.getItem("AccessToken");
  const FILTER_URL = `${baseUrl.baseUrl}api/challenges-info/`;
  const today = new Date();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleusertype = (event) => {
    setusertype(event.target.value);
  };
  const handlechallengetype = (event) => {
    if (event.target.value.length === 1) {
      setchallengetype(event.target.value);
      setChallengeBody(event.target.value[0]);
    } else {
      setchallengetype(event.target.value);
      setChallengeBody([]);
    }
  };

  const handleSubmit = () => {
    dispatch(Actions.sendIsLoading(true));
    const filterData = {
      user_id: usertype,
      challenge_type: challengeBody,
      start_date: startDate,
      end_date: endDate,
    };
    axios
      .post(`${baseUrl.baseUrl}api/challenges-info/}`, filterData, { headers })
      .then(function (response) {
        dispatch(Actions.sendChallengeGraphData(response.data));
        setResponse(response.data);
        dispatch(Actions.sendIsLoading(false));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {" "}
      <div className="dashboard-filters-container">
        <Grid container spacing={2}>
          <Grid item md={2}>
            <FormControl style={{ width: "100%" }} size="small">
              <TextField
                id="outlined-basic"
                label="User Id"
                variant="outlined"
                type="number"
                size="small"
                onChange={handleusertype}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl
              style={{ width: "100%", textAlign: "left" }}
              size="small"
            >
              <div className="filterfields">
                <InputLabel id="label1">Challenge Type</InputLabel>
                <Select
                  labelId="challengetype"
                  id="challengetype"
                  style={{ margin: 0, width: "100%" }}
                  value={challengetype}
                  onChange={handlechallengetype}
                  input={<OutlinedInput label="ChallengeType" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  multiple
                >
                  {challengemodes.map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={challengetype.indexOf(type) > -1} />
                      <ListItemText primary={type} />
                      {/* {type} */}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={2.5}>
            <FormControl style={{ width: "100%" }} size="small">
              <TextField
                defaultValue={moment(today).format("YYYY-MM-DD")}
                inputProps={{
                  max: today.toISOString().slice(0, 10),
                }}
                required
                variant="outlined"
                label="Start Date"
                type="date"
                size="small"
                id="textField"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: 0, width: "100%" }}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setEndDate(moment(today).format("YYYY-MM-DD"));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2.5}>
            <FormControl style={{ width: "100%" }} size="small">
              <TextField
                // defaultValue={moment().format("YYYY-MM-DD")}
                value={endDate}
                inputProps={{
                  min: startDate,
                  max: today.toISOString().slice(0, 10),
                }}
                required
                variant="outlined"
                label="End Date"
                type="date"
                id="textField"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ margin: 0, width: "100%" }}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{
                margin: 0,
                padding: 6,
                width: "100%",
                backgroundColor: "#1c226b",
                textTransform: "capitalize",
              }}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isloading && isloading}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CircularProgress
            size={"4rem"}
            style={{
              color: "#1c226b",
              margin: "auto",
            }}
          />
        </Box>
      </Backdrop>
    </>
  );
}
