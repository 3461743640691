import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import Reducer from "../Redux/Reducers";
import AdminPrivateRoutes from "./AdminRoutes"
import Login from "../Pages/Login";

const store = createStore(Reducer);
const PrivateRoute = ({ children }) => {
    const isLogin = localStorage.getItem("AccessToken");
    return isLogin ? children : <Navigate to="/" />;
};

const PrivateRoutes = () => {
    return (
        <Provider store={store}>
            <Routes>
                {/* PublicRoutes */}
                <Route path="/" element={<Login />} />
                {/* PrivateRoutes */}
                <Route path="/Admin/*" element={<PrivateRoute><AdminPrivateRoutes /></PrivateRoute>}></Route>
            </Routes>
        </Provider>
    )
}

export default PrivateRoutes;
