import React from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";

export default function ChallengeTable(props) {
  const graphData = useSelector((state) => state.graphData);
  return (
    <>
      <Table
        className="dashboard-table"
        style={{ height: "100%", backgroundColor: "#fff" }}
      >
        <TableBody>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Total Challenges
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.Total_challenges
                : props.data
                ? props.data.Total_challenges
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Tax
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData ? graphData.Tax : props.data ? props.data.Tax : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Lost Amount
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.lost_amount
                : props.data
                ? props.data.lost_amount
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              On Going
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.on_going
                : props.data
                ? props.data.on_going
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Private
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.private
                : props.data
                ? props.data.private
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Public
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.Public
                : props.data
                ? props.data.Public
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Refunded
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.refunded
                : props.data
                ? props.data.refunded
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Settled
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.settled
                : props.data
                ? props.data.settled
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Stake Amount
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.stack_amount
                : props.data
                ? props.data.stack_amount
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="dashboard-table dashboard-cell dashboard-left-cell">
              Winning Amount
            </TableCell>
            <TableCell className="dashboard-table dashboard-cell dashboard-right-cell">
              {graphData
                ? graphData.winning_amount === null
                  ? 0
                  : graphData.winning_amount
                : props.data
                ? props.data.winning_amount === null
                  ? 0
                  : props.data.winning_amount
                : null}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
