import { useRef } from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, FormControlLabel, Switch } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ReorderIcon from "@mui/icons-material/Reorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import baseUrl from "../../Config/config.js";
import axios from "axios";
import { toast } from "react-hot-toast";
import * as Actions from "../../Redux/Action";
import "../../Styles/ImageUpload.css";
import { useSelector } from "react-redux";

const style = {
  border: "1px solid #000",
  padding: "0.65rem 1rem",
  marginBottom: ".5rem",
  borderRadius: "5px",
  //backgroundColor: "white",
  cursor: "move",
};

export const Card = ({ id, card, index, moveCard, callback, getnewlist }) => {
  const adminpermissions = useSelector((state) => state.permissionsData);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const token = localStorage.getItem("AccessToken");

  const handledelete = (image_id) => {
    callback(image_id);
  };

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      adminpermissions.permission?.CarouselImages.update
        ? moveCard(dragIndex, hoverIndex)
        : toast.error("Access denied", { duration: 1000 });
      // Note: we're mutating the monitor item here!
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleActive = (id, isActive) => {
    // dispatch(Actions.getImagelist(""));

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const data = {
      id: id,
      is_active: !isActive,
    };

    axios
      .put(`${baseUrl.baseUrl}api/images/is_active/`, data, { headers })
      .then((response) => {
        // dispatch(Actions.getImagelist(response.data.message));
        getnewlist();
        toast.success("updated successfully", { duration: 1000 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Grid
        container
        ref={ref}
        style={{ ...style, opacity }}
        data-handler-id={handlerId}
      >
        <Grid item xs={2} className="card-grid">
          <ReorderIcon />
        </Grid>
        <Grid item xs={2} className="card-grid">
          <FormControlLabel
            style={{ marginRight: "0px" }}
            control={
              <Switch
                checked={card.is_active}
                onChange={() =>
                  adminpermissions &&
                  adminpermissions.permission?.CarouselImages.update
                    ? handleActive(card.id, card.is_active)
                    : toast.error("Access denied", {duration:1000})
                }
                name="loading"
                color="primary"
              />
            }
          />
        </Grid>
        <Grid item xs={3} className="card-grid">
          <div
            className="image-name"
            style={{
              fontSize: "12px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {card.image_name}
          </div>
        </Grid>
        <Grid item xs={3} className="card-grid">
          {/* <Button> view </Button> */}
          <img
            style={{ height: "30px", width: "100px" }}
            src={process.env.PUBLIC_URL + card.url}
          />
        </Grid>
        <Grid item xs={2} className="card-grid">
          <Button
            onClick={() =>
              adminpermissions &&
              adminpermissions.permission?.CarouselImages.update
                ? handledelete(card.id)
                : toast.error("Access denied", {duration:1000})
            }
          >
            <FontAwesomeIcon style={{ color: "#f44336" }} icon={faTrash} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
