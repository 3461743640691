import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  Grid,
  Box,
} from "@mui/material";
import "../../Styles/Sports.css";
import { useSelector,useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import * as Actions from "../../Redux/Action";
import baseUrl from "../../Config/config";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const columns = [
  { id: "id", label: "Sport Id" },
  { id: "name", label: "Name" },
  {
    id: "alias",
    label: "Alias",
  },
  {
    id: "type",
    label: "Type",
  },
];

export default function SportsModal(props) {
  const isloading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [rows, setRows] = useState();
  const [isSportsDataEmpty, setIsSportsDataEmpty] = useState(false);
  const sportslist = props?.sportsData;
  const CREATE_SPORT_URL = `${baseUrl.baseUrl}api/sports/`;
  const token = localStorage.getItem("AccessToken");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [state, setState] = React.useState(null);

  const sportsarray = Object.keys(sportslist).map((key) => {
    return sportslist[key];
  });
  const sportsdropdown =
    sportsarray &&
    sportsarray.map((list) => {
      const name = list.name[0].toUpperCase();
      return {
        name: /[0-9]/.test(name) ? "0-9" : name,
        ...list,
      };
    });
  // const sportsdropdown = []

  const handleSubmit = () => {
    dispatch(Actions.sendIsLoading(true));
    let sportData;
    if (state !== null) {
      sportData = {
        bet_construct_id: state.id,
        sport_name: state.name,
        alias_name: state.alias,
        order: state.order,
      };
      axios
        .post(CREATE_SPORT_URL, sportData, { headers })
        .then(function (response) {
          dispatch(Actions.sendIsLoading(false));
          dispatch(Actions.sendcreatedsport(response.data));
          dispatch(Actions.sendIsSportCreated(true));
          props.onClose();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setIsSportsDataEmpty(true);
    }
  };

  const handleChange = (newVal) => {
    setIsSportsDataEmpty(false);
    if (newVal) {
      setState(newVal);
      const newRows = {
        alias: newVal.alias,
        id: newVal.id,
        name: newVal.name,
        type: newVal.type,
      };
      setRows(newRows);
    } else {
      setState(null);
      setRows("");
    }
  };

  useEffect(() => {
  }, [state]);

  const handleCreateClose = () => {
    props.onClose();
  };

  return (
    <div>
      <Grid container style={{ justifyContent: "end", padding: "5px" }}>
        <CloseIcon
          onClick={() => handleCreateClose()}
          style={{ cursor: "pointer" }}
        />
      </Grid>
      <DialogTitle
        style={{
          textAlign: "center",
          color: "#1c226b",
          fontWeight: "bolder",
          padding:"0px 24px"
        }}
      >
        SPORT
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Autocomplete
            style={{ width: "100%" }}
            id="grouped-demo"
            options={
              sportsdropdown
                ? sportsdropdown.sort((a, b) => -b.name.localeCompare(a.name))
                : "Select value"
            }
            getOptionLabel={(list) => JSON.stringify(list)}
            sx={{ width: 300 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, newValue) => {
              handleChange(newValue);
            }}
            value={state}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                variant="standard"
                label="Select sports"
                required
                error={isSportsDataEmpty}
                helperText={isSportsDataEmpty ? "Please select any sport" : ""}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  "& > img": { mr: 2, flexShrink: 0 },
                  "& > span": { paddingRight: "5px" },
                }}
                {...props}
              >
                <span>{option.id}.</span>
                {option.name} ({option.alias})
              </Box>
            )}
          />
        </div>
        <div>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ marginBottom: "10px" }}
          >
            <TableHead id="table-head" style={{ border: "1px solid black" }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align="center">
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && (
                <TableRow
                  key={rows.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="td" scope="row" align="center">
                    {rows.id}
                  </TableCell>
                  <TableCell align="center">{rows.name}</TableCell>
                  <TableCell align="center">{rows.alias}</TableCell>
                  <TableCell align="center">{rows.type}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div>
          <Button
            variant="contained"
            type="submit"
            style={{
              textTransform: "capitalize",
              backgroundColor: "#1c226b",
              width: "100%",
            }}
            onClick={() => handleSubmit()}
          >
            SUBMIT&nbsp;
          </Button>
        </div>
      </DialogContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isloading && isloading}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CircularProgress
            size={"4rem"}
            style={{
              color: "#1c226b",
              margin: "auto",
            }}
          />
        </Box>
      </Backdrop>
    </div>
  );
}
