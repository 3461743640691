import React, { useState } from "react";
import * as Actions from "../../Redux/Action";
import { useDropzone } from "react-dropzone";
import "../../Styles/ImageUpload.css";
import { useDispatch } from "react-redux";
import { Grid, Button } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ViewImages from "./ViewImages";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from "axios";
import baseUrl from "../../Config/config";
import { toast } from "react-hot-toast";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";

export default function UploadImage(props) {
  const dispatch = useDispatch();
  const adminpermissions = useSelector((state) => state.permissionsData);
  const token = localStorage.getItem("AccessToken");
  const [selectedImage, setSelectedImage] = useState(null);
  const [files, setfiles] = useState(null);
  const [dimensionserror, setdimensionserror] = useState(false);
  const [sizeerror, setsizeerror] = useState(false);
  const [btndisable, setbtndisable] = useState(true);

  const requiredwidth = 1280;
  const requiredheight = 325;
  const maxsize = 1000000;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: { "image/*": [] },
  });

  function handleFileUpload(files) {
    dispatch(Actions.getImagelist(""));
    const selectedFile = files[0];
    setfiles(selectedFile && selectedFile);
    if (selectedFile && selectedFile.length > 0) {
      setbtndisable(false);
    }
    if (selectedFile.size > maxsize) {
      setsizeerror(true);
      setbtndisable(true);
    } else {
      setbtndisable(false);
    }
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        if (width < requiredwidth || height < requiredheight) {
          setdimensionserror(true);
          setbtndisable(true);
        } else {
          setbtndisable(false);
          setdimensionserror(false);
        }
        setSelectedImage(event.target.result);
      };
    };
  }

  //api to upload the images
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const submitHandler = async () => {
    dispatch(Actions.sendIsLoading(true));
    let formData = new FormData();
    formData.append("image_file", files);
    axios
      .post(`${baseUrl.baseUrl}api/images/image_upload/`, formData, { headers })
      .then((response) => {
        toast.success(response.data.message, { duration: 1000 });
        setSelectedImage("");
        setbtndisable(true);
        dispatch(Actions.getImagelist(response.data.message));
      })
      .catch((error) => {
        toast.error(error.response.data.error, { duration: 1000 });
        dispatch(Actions.sendIsLoading(false));
      });
  };

  const removeimage = () => {
    setsizeerror(false);
    setdimensionserror(false);
    setSelectedImage("");
    setbtndisable(true);
  };

  return (
    <>
      <div>
        <h2 id="headingtxt">Carousel Images</h2>
      </div>
      <Grid container spacing={2} className="dropzone-container">
        <Grid item xs={6} style={{ height: "60vh" }}>
          <div
            className="dropzone"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {selectedImage && files && (
              <div style={{ display: "flex" }}>
                <CancelIcon
                  onClick={removeimage}
                  style={{ cursor: "pointer", fontSize: "15px" }}
                />
              </div>
            )}
            <div
              className="ondrop"
              onClick={
                !adminpermissions.permission.CarouselImages.create
                  ? () => toast.error("Access denied", {duration:1000})
                  : ""
              }
              onDrop={
                !adminpermissions.permission.CarouselImages.create
                  ? () => toast.error("Access denied", {duration:1000})
                  : ""
              }
              {...(adminpermissions.permission.CarouselImages.create
                ? getRootProps()
                : "")}
            >
              <input {...getInputProps()} />
              {selectedImage ? (
                <div style={{display:'flex', overflow:'hidden', height:"100%"}}>
                  <img
                    src={selectedImage}
                    alt="selected"
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
              ) : (
                <AddPhotoAlternateIcon
                  style={{ height: "50%", width: "20%" }}
                />
              )}
              <p style={{ fontWeight: "600" }}>
                Drag and drop your files here, or click to select files
              </p>
              <div className="error-msgs" style={{ lineHeight: "5px" }}>
                {dimensionserror && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    *the min required dimensions are, width '{requiredwidth}'
                    and height '{requiredheight}'
                  </p>
                )}
                {sizeerror && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    *the image size exceeded the max limit of '
                    {maxsize / 1000000}
                    mb'
                  </p>
                )}
              </div>
              {selectedImage && files ? (
                <li key={files.path} style={{ fontSize: "15px" }}>
                  {files.path} - {files.size} bytes
                </li>
              ) : (
                ""
              )}
            </div>
          </div>
          <Button
            variant="contained"
            disabled={btndisable}
            size="small"
            style={{
              margin: "10px",
              width: "200px",
              backgroundColor: !btndisable ? "#1c226b" : "",
            }}
            onClick={submitHandler}
          >
            Submit
          </Button>
        </Grid>
        <Grid item xs={6} style={{ height: "70vh" }}>
          <div className="table-dropzon">
            <DndProvider backend={HTML5Backend}>
              <ViewImages images={props.images} />
            </DndProvider>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
