import React, { useState } from "react";
import { Paper, Button, Skeleton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import baseUrl from "../../Config/config";
import "../../Styles/Reports.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector , useDispatch } from "react-redux";
import * as Actions from "../../Redux/Action";
import { toast } from "react-hot-toast";

const columns = [
  { id: "challengeid", label: "Challenge Id" },
  {
    id: "sportname",
    label: "Sport Name",
  },
  {
    id: "matchid",
    label: "Match Id ",
  },
  {
    id: "matchname",
    label: "Match Name",
  },
  {
    id: "matchdate",
    label: "Match Date",
  },
  {
    id: "marketname",
    label: "Market Type",
  },
  {
    id: "createdby",
    label: "Created User",
  },
  {
    id: "selecteduser",
    label: "Selected User",
  },
  {
    id: "result",
    label: "Result",
  },
  {
    id: "stakeamount",
    label: "Stake Amount",
  },
  {
    id: "Actions",
    label: "Actions",
  },
];

const PayWinnersFilterTable = () => {
  const token = localStorage.getItem("AccessToken");
  const adminpermissions = useSelector((state) => state.permissionsData);
  const [rows, setRows] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [open, setopen] = useState(false);
  const [teamnames, setteamnames] = useState({});
  const [result, setresult] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = () => {
    axios
      .get(`${baseUrl.baseUrl}api/admin-refund/`, { headers })
      .then(function (response) {
        setIsLoaded(true);
        handleData(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleData = (data) => {
    const updatedRows = data.map((item,index) => {
      const results = [];
      return {
        key: index,
        challengeid: item.challenge,
        sportname: item.sport_name,
        matchid: item.game_id,
        matchname: (
          <Button onClick={() => opendialog(item.team1, item.team2)}>
            {" "}
            view{" "}
          </Button>
        ),
        matchdate: formatDate(item.game_date),
        marketname: item.market_name,
        createdby: item.created_user,
        selecteduser: item.selected_by,
        result: <Button onClick={() => opendialog(item.result)}> view </Button>,
        stakeamount: item.stack_amount,
        // Actions: <Button onClick={() => handleRefund(item.challenge)}> Refund
        // </Button>
        Actions: (
          <Button
            className="btn"
            variant="contained"
            onClick={() =>
              adminpermissions.permission &&
              adminpermissions.permission.Refund.update
                ? handleRefund(item.id)
                : toast.error("Access denied",{ duration: 1000 })
            }
          >
            {"Refund"}
          </Button>
        ),
      };
    });
    setRows(updatedRows);
  };

  const formatDate = (val) => {
    const dateVal = new Date(val);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = dateVal.toLocaleDateString("en-GB", options);
    // const formattedTime = dateVal.toLocaleTimeString();
    return `${formattedDate}`;
  };

  const handleRefund = (_id) => {
    dispatch(Actions.sendIsLoading(true));
    const refundData = { ids: [_id] };
    axios
      .put(
        `${baseUrl.baseUrl}api/admin-refund/`,
        { ...refundData },
        { headers }
      )
      .then(function (response) {
        // update the data in the state if the delete was successful\
        dispatch(Actions.sendIsLoading(false));
        setRows((prevRows) => prevRows.filter((row) => row._id !== _id));
        fetchData();
        toast.success("Successfully Refunded",{ duration: 1000 });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const opendialog = (data1, data2) => {
    // data1 && data2 && setteamnames({
    //   team1: data1,
    //   team2: data2
    // })
    {
      data1 &&
        data2 &&
        setteamnames({
          team1: data1,
          team2: data2,
        });
      setresult("");
    }
    !data2 && setresult(data1);
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  return (
    <>
      {isLoaded ? (
        <div style={{ margin: "20px 10px" }}>
          <div>
            <Paper
              sx={{
                width: "100%",
                backgroundColour: "black",
                overflow: "hidden",
              }}
            >
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column,index) => (
                        <TableCell
                          key={index}
                          align="center"
                          id="table-row"
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows && rows.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={columns.length} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                    {rows &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row,index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align="center"
                                    style={{ padding: "10px 0px" }}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Dialog
                PaperProps={{
                  style: {
                    backgroundColor: "white",
                    boxShadow: "none",
                  },
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Roboto,Arial,Helvetica,sans-serif",
                  }}
                >
                  {!result.length > 0 ? (
                    <span>
                      {teamnames && teamnames.team1}{" "}
                      <span className="vs-text" style={{ fontWeight: "bold" }}>
                        vs
                      </span>{" "}
                      {teamnames && teamnames.team2}
                    </span>
                  ) : (
                    <ul>
                      {result.map((results, index) => (
                        <li key={index}>{results}</li>
                      ))}
                    </ul>
                  )}
                </DialogTitle>
              </Dialog>
            </Paper>
          </div>
        </div>
      ) : (
        <div className="table-sports">
          <Paper
            sx={{
              width: "100%",
              backgroundColour: "black",
              overflow: "hidden",
            }}
          >
            <TableContainer>
              <Table aria-label="table skeleton">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align="center" id="table-row">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...Array(5)].map((_, index) => (
                    <TableRow key={index}>
                      {[...Array(11)].map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  );
};

export default PayWinnersFilterTable;
