import React from "react";
import NavBar from "../Components/NavBar";
import UploadImage from "../Components/CarouselComponents/UploadImage";
import baseUrl from "../Config/config";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../Redux/Action";

export default function CarouselImages() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("AccessToken");
  const [images, setimages] = React.useState();
  const getImagelist = useSelector((state) => state.getImagelist);

  //get list of the images
  React.useEffect(() => {
    axios
      .get(`${baseUrl.baseUrl}api/images/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(Actions.sendIsLoading(false));
        setimages(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getImagelist && getImagelist]);


  return (
    <div>
      <NavBar />
      {images ? (
        <UploadImage images={images} />
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CircularProgress
              size={"4rem"}
              style={{
                color: "#1c226b",
                margin: "auto",
              }}
            />
          </Box>
        </Backdrop>
      )}
    </div>
  );
}
