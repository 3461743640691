import React from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function Challengespie(props) {
  const pieData = useSelector((state) => state.graphData);
  let options;

  if (pieData) {
    const onGoing = parseFloat(pieData.on_going.replace("%", ""));
    const refunded = parseFloat(pieData.refunded.replace("%", ""));
    const settled = parseFloat(pieData.settled.replace("%", ""));
    Highcharts.setOptions({
      colors: [
        "#7cb5ec",
        "#f7a35c",
        "#90ee7e",
      ],
      chart: {
        backgroundColor: "white",
        borderWidth: 0  ,
        plotBackgroundColor: "rgba(255, 255, 255, .9)",
        plotShadow: true,
        plotBorderWidth: 0,
      },
      credits: {
        enabled: false
      },
    });

    options = {
      colors: [
        "#7cb5ec",
        "#f7a35c",
        "#90ee7e",
      ],
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height:400,
        width:400,
        type: "pie",
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      title: {
        text: "Total Challenges",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: [
            {
              name: "On Going",
              y: onGoing,
              sliced: true,
              selected: true,
            },
            {
              name: "Refunded",
              y: refunded,
            },
            {
              name: "Settled",
              y: settled,
            },
          ],
        },
      ],
    };
  } else if (props.data && props.data) {
    const onGoing = parseFloat(props.data.on_going.replace("%", ""));
    const refunded = parseFloat(props.data.refunded.replace("%", ""));
    const settled = parseFloat(props.data.settled.replace("%", ""));

    Highcharts.setOptions({
      colors: [
        "#7cb5ec",
        "#f7a35c",
        "#90ee7e",
      ],
      chart: {
        backgroundColor: "#fff",
        borderWidth: 0,
        plotBackgroundColor: "rgba(255, 255, 255, .9)",
        plotShadow: true,
        plotBorderWidth: 0,
      },
      credits: {
        enabled: false
      },
    });

    options = {
      colors: [
        "#7cb5ec",
        "#f7a35c",
        "#90ee7e",
      ],
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height:400,
        width:400,
        type: "pie",
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      title: {
        text: "Total Challenges",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: [
            {
              name: "On Going",
              y: onGoing,
              sliced: true,
              selected: true,
            },
            {
              name: "Refunded",
              y: refunded,
            },
            {
              name: "Settled",
              y: settled,
            },
          ],
        },
      ],
    };
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
