import React from "react";
import NavBar from "../Components/NavBar";
import RefundUsersFilterTable from "../Components/RefundUsersComponents/RefundUsersFilterTable";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useSelector } from "react-redux";

export default function RefundUsers() {
  const isloading = useSelector((state) => state.isLoading);
  return (
    <div>
      <NavBar />
      <div>
        <div>
          <h2 id="headingtxt">Refund Users</h2>
        </div>
        <RefundUsersFilterTable />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isloading && isloading}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CircularProgress
            size={"4rem"}
            style={{
              color: "#1c226b",
              margin: "auto",
            }}
          />
        </Box>
      </Backdrop>
    </div>
  );
}
