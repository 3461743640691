import React, { useState } from "react";
import "../Styles/Login.css";
import logo from "../Assets/logo.png";
import {
  Grid,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import baseUrl from "../Config/config";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import * as Actions from "../Redux/Action";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ForgetPassword from "./ForgetPassword";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required*";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address*";
  }
  if (!values.password) {
    errors.password = "Required*";
  }
  // } else if (!/^[a-zA-Z0-9]{6,20}$/i.test(values.password)) {
  //   errors.password = "Invalid password";
  // }
  return errors;
};

export default function LoginNew() {
  var CryptoJS = require("crypto-js");
  localStorage.removeItem("AccessToken");
  localStorage.removeItem("permissions");
  // localStorage.removeItem("pass")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isForget, setIsForget] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      axios
        .post(`${baseUrl.baseUrl}api/admin-login/`, {
          email: formik.values.email,
          password: formik.values.password,
        })
        .then((res) => {
          console.log("response", res.data.user_info)
          const userdetails = {
            first_name : res && res.data.user_info.first_name,
            last_name : res && res.data.user_info.last_name
          }
          console.log("userdetails", userdetails)
          userdetails && dispatch(Actions.senduserdetails(userdetails))
          toast.success("Logged in successfully", { duration: 1000 });
          dispatch(Actions.sendPermissionsData(res?.data?.permissions));
          // localStorage.setItem(
          //   "pass",
          //   CryptoJS.AES.encrypt(
          //     JSON.stringify(formik.values.password),
          //     "my-secret-key@123"
          //   ).toString()
          // );
          localStorage.setItem(
            "permissions",
            CryptoJS.AES.encrypt(
              JSON.stringify(res?.data?.permissions),
              "my-secret-key@123"
            ).toString()
          );
          localStorage.setItem("AccessToken", res.data.access_token);
          localStorage.setItem("userEmail", res.data.user_info.email);
          sessionStorage.setItem("isLoggedIn", "true");
          navigate("/Admin/");
        })
        .catch((err) => {
          console.log("responseerr", err);
          toast.error("Incorrect Username or Password", { duration: 1000 });
        });
    },
  });

  const handleForgetPassword = () => {
    setIsForget(true);
  };

  const goBackHandler = () => {
    setIsForget(false);
  };

  return (
    <>
      <div className="login-page">
        <div className="formholder">
          <motion.div
            className="container"
            initial={{ scale: 0 }}
            animate={{ rotate: 0, scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 10,
            }}
          >
            {!isForget ? (
              <form className="logincontainer" onSubmit={formik.handleSubmit}>
                <img src={logo} alt="logoimage" className="logoimage"></img>
                <span
                  id="txt1"
                  style={{
                    font: "1rem secondaryFont, sans-serif",
                  }}
                >
                  Welcome to P2P
                </span>
                <span
                  id="txt1"
                  style={{
                    font: "1rem secondaryFont, sans-serif",
                  }}
                >
                  Please enter the below details.
                </span>
                <Grid className="textfields">
                  <Grid item style={{ height: "80px" }}>
                    <InputLabel
                      htmlFor="demo-customized-textbox"
                      id="inputlabels"
                    >
                      Email address
                    </InputLabel>
                    <TextField
                      id="inputboxe1"
                      margin="dense"
                      name="email"
                      size="small"
                      autoFocus={true}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputProps={{
                        style: {
                          backgroundColor: "rgb(232, 240, 254)",
                          color: "black",
                          borderColor: "white",
                          font: "0.8rem secondaryFont, sans-serif",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ width: "30px", marginRight: "-8px" }}
                          >
                            <IconButton edge="end">
                              {formik.touched.email && formik.errors.email ? (
                                <ErrorOutlineIcon
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "red",
                                  }}
                                />
                              ) : null}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item style={{ height: "80px" }}>
                    <InputLabel
                      htmlFor="demo-customized-textbox"
                      id="inputlabels"
                      // style={{ marginTop: "0px" }}
                    >
                      Password
                    </InputLabel>
                    <TextField
                      type="password"
                      id="inputboxe2"
                      name="password"
                      margin="dense"
                      size="small"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      InputProps={{
                        style: {
                          backgroundColor: "rgb(232, 240, 254)",
                          color: "black",
                          borderColor: "white",
                          font: "0.8rem secondaryFont, sans-serif",
                        },
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ width: "30px", marginRight: "-8px" }}
                          >
                            <IconButton edge="end">
                              {formik.touched.password &&
                              formik.errors.password ? (
                                <ErrorOutlineIcon
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    color: "red",
                                  }}
                                />
                              ) : null}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <p onClick={handleForgetPassword} className="forget-passwd">
                    forgot your password ?
                  </p>
                  <Button
                    className="loginbtn"
                    variant="contained"
                    color="success"
                    type="submit"
                    style={{
                      font: "1rem secondaryFont, sans-serif",
                      marginTop: "10px",
                      width: "298px",
                    }}
                  >
                    LOGIN
                  </Button>
                </Grid>
              </form>
            ) : (
              <ForgetPassword onGoBack={goBackHandler} />
            )}
          </motion.div>
        </div>
      </div>
    </>
  );
}
