import React, { useState, useEffect } from "react";
import { Dialog, Button } from "@mui/material";
import SportsTable from "../Components/SportsComponents/SportsTable";
import AddIcon from "@mui/icons-material/Add";
import NavBar from "../Components/NavBar";
import SportsModal from "../Components/SportsComponents/SportsModal";
import "../Styles/Sports.css";
import { SocketCall } from "../utils/WebSockets";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import * as Actions from "../Redux/Action";
import { toast } from "react-hot-toast";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Sports = () => {
  const dispatch = useDispatch();
  const adminpermissions = useSelector((state) => state.permissionsData);
  // console.log("adminpermissions", adminpermissions)
  const [open, setOpen] = useState(false);
  const [sportsData, setSportsData] = useState("");
  const isCreatedSport = useSelector((state) => state.isSportCreated);
  const isDeletedSport = useSelector((state) => state.isSportDeleted);

  useEffect(() => {
    const reqApi = {
      command: "request_session",
      params: {
        site_id: 1334,
        language: "en",
      },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const allSports = {
      command: "get",
      params: {
        source: "betting",
        what: {
          sport: [],
        },
        where: {},
      },
    };

    const websocket = new SocketCall();
    (async () => {
      var response = await websocket.sendcommand(allSports);
      // console.log("response", response)
      const sportsData =
        response &&
        response.data &&
        response.data.data &&
        response.data.data.sport;
      setSportsData(sportsData);
    })();
  }, []);

  const handleAddNew = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isCreatedSport) {
      toast.success("Successfully Created",{ duration: 1000 });
      dispatch(Actions.sendIsSportCreated(false));
    }
    if (isDeletedSport) {
      toast.success("Successfully Deleted",{ duration: 1000 });
      dispatch(Actions.sendIsSportDeleted(false));
    }
  }, [isCreatedSport, isDeletedSport]);

  return (
    <>
      <NavBar />
        <div>
          <div>
            <h2 id="headingtxt">Sports</h2>
          </div>
          {
            <Button
              variant="outlined"
              style={{
                margin: "0px 20px 10px",
                color: "#1c226b",
                textTransform: "capitalize",
                float: "left",
                marginLeft: "10px",
                borderRadius: "5px",
              }}
              onClick={() =>
                adminpermissions &&
                adminpermissions.permission.Sport.create === true &&
                sportsData
                  ? handleAddNew()
                  : toast.error("Access denied",{ duration: 1000 })
              }
            >
              <AddIcon />
              &nbsp;&nbsp;Add New
            </Button>
          }
          <div>
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={open}
              onClose={handleClose}
            >
              <div>
                {sportsData && (
                  <SportsModal
                    onClose={handleClose}
                    sportsData={sportsData && sportsData}
                  />
                )}
              </div>
            </Dialog>
          </div>
          <div>
            <SportsTable />
          </div>
        </div>
    </>
  );
};

export default Sports;
