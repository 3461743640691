import React from "react";
import "./App.css";
import Routers from "./Routes/Router";
import { EstablishSocketConnection } from "./utils/WebSockets";
import { toast, Toaster } from "react-hot-toast";
import Pagenotfound from "./Assets/errorImage1.jpg";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ErrorFallback({ error, resetErrorBoundary }) {
  const navigate = useNavigate();
  localStorage.removeItem("AccessToken");
  localStorage.removeItem("permissions");
  navigate("/");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        flexDirection: "column",
        width: "50%",
        height: "auto",
        marginTop: "40px",
        gap: "10px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "60%",
          border: "tranparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={Pagenotfound}
          width="70%"
          height="70%"
          alt="img"
          object-fit="cover"
        />
      </div>
      <div style={{ font: "20px Roboto,Arial,Helvetica,sans-serif" }}>
        Something went wrong, please try again
      </div>
      <button
        onClick={resetErrorBoundary}
        style={{
          background: "#1c226b",
          color: "#ffffff",
          padding: "5px",
          borderRadius: "5px",
          font: "10px Roboto,Arial,Helvetica,sans-serif",
          cursor: "pointer",
        }}
      >
        Refresh
      </button>
    </div>
  );
}
function App() {
  const navigate = useNavigate();
  let toastDisplayed = false;

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401 && !toastDisplayed) {
        console.log("inside");
        // toast.error("Session Expired");
        navigate("/");
        localStorage.clear();
        toastDisplayed = true;
      }
      return Promise.reject(error);
    }
  );

  React.useEffect(() => {
    EstablishSocketConnection();
    console.log("admin app is rendering")
  }, []);
  
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <div className="App">
        <Toaster position="top-right" reverseOrder={false} />
        <Routers />
      </div>
    </ErrorBoundary>
  );
}

export default App;
