import React from "react";
import {
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import baseUrl from "../Config/config";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Actions from "../Redux/Action";
import { useDispatch } from "react-redux";

export default function ChangePassword(props) {
  const dispatch = useDispatch()
  const token = localStorage.getItem("AccessToken");
  const email = localStorage.getItem("userEmail");
  const navigate = useNavigate();
  const password = useSelector((state) => state.pass);
  console.log("password", password)
  const validate = (values) => {
    const errors = {};
    if (!values.oldPassword) {
      errors.oldPassword = "Required*";
    }
    if (!values.newPassword) {
      errors.newPassword = "Required*";
    }
    // if (values.oldPassword && values.oldPassword !== password) {
    //   errors.oldPassword = "password not matched";
    // }
    if (
      values.newPassword &&
      values.newPassword === values.oldPassword &&
      values.oldPassword
    ) {
      errors.newPassword = "passwords are same*";
    }
    if (
      values.oldPassword &&
      values.oldPassword === values.newPassword &&
      values.newPassword
    ) {
      errors.oldPassword = "passwords are same*";
    }

    return errors;
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      axios
        .put(
          `${baseUrl.baseUrl}api/change-admin-password/`,
          {
            email: email,
            old_password: formik.values.oldPassword,
            new_password: formik.values.newPassword,
            new_login: false,
          },
          { headers }
        )
        .then((res) => {
          if (props.newuser) {
            toast.success(
              "please re-login with new password",
              { duration: 1000 }
            );
            props.onClose();
            setTimeout(() => {
              localStorage.removeItem("AccessToken");
              localStorage.removeItem("permissions");
              // localStorage.removeItem("pass");
              navigate("/");
            }, 2000);
          } else {
            props.onClose();
            toast.success("Password changed successfully", { duration: 1000 });
          }
        })
        .catch((err) => {
          console.log("responseerr", err);
          toast.error(err.response.data.message, { duration: 1000 });
        });
    },
  });

  const handleClose = () => {
    props.onClose();
  };

  return (
    <div>
      <Grid container style={{ justifyContent: "end", padding: "5px" }}>
        <CloseIcon
          onClick={() => handleClose()}
          style={{ cursor: "pointer" }}
        />
      </Grid>
      <DialogTitle
        style={{
          textAlign: "center",
          color: "#1c226b",
          fontWeight: "bolder",
          padding: "0px 24px",
        }}
      >
        Change Password
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="oldpassword"
                label="Old password*"
                variant="standard"
                name="oldPassword"
                value={formik.values.oldPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.oldPassword &&
                  Boolean(formik.errors.oldPassword)
                }
                helperText={
                  formik.touched.oldPassword && formik.errors.oldPassword
                }
                InputProps={{
                  autoComplete: "off",
                }}
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label="New password*"
                name="newPassword"
                variant="standard"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  autoComplete: "off",
                }}
                style={{ width: "100%", marginBottom: "10px" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ justifyContent: "center", marginTop: "10px" }}
          >
            {/* <Grid item xs={2} style={{ paddingRight: "2px" }}>
              <Button
                variant="contained"
                style={{
                  textTransform: "capitalize",
                  backgroundColor: "#1c226b",
                  width: "100%",
                }}
              >
                Clear
              </Button>
            </Grid> */}
            <Grid item xs={6}>
              <Button
                disabled={
                  formik.errors.newPassword ||
                  formik.errors.oldPassword ||
                  !formik.values.oldPassword ||
                  !formik.values.newPassword
                    ? true
                    : false
                }
                variant="contained"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  backgroundColor:
                    formik.errors.newPassword ||
                    formik.errors.oldPassword ||
                    !formik.values.oldPassword ||
                    !formik.values.newPassword
                      ? "rgba(0, 0, 0, 0.05)"
                      : "#1c226b",
                  width: "100%",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </div>
  );
}
