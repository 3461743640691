import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function ChallengeGraph(props) {
  const [chartOptions, setChartOptions] = useState({});
  const graphData = useSelector((state) => state.graphData);

  useEffect(() => {

    if (graphData) {

      // const data = [
      //   ...Object.entries(graphData.chart).map(([day, density]) => [
      //     day,
      //     density,
      //   ]),
      // ];

      setChartOptions({
        chart: {
          type: "column",
          borderWidth: 0,
          height:310
        },
        title: {
          text: "Number of challenges",
          style: {
            fontWeight: "bold",
          },
        },
        xAxis: {
          categories: Object.keys(graphData.chart),
        },
        yAxis: {
          title: {
            text: "Count",
          },
        },
        plotOptions: {
          series: {
            color: "rgb(68,114,196)",
          },
          column: {
            shadow: {
              color: "rgba(0,0,0,0.5)",
              width: 5,
              offsetX: 2,
              offsetY: 2,
            },
          },
        },
        series: [
          {
            name: "Count",
            data: Object.values(graphData.chart),
          },
        ],
      });
    } else if (props.data) {
      setChartOptions({
        chart: {
          type: "column",
          borderWidth: 0,
          height:310
        },
        title: {
          text: "Number of Challenges",
          style: {
            fontWeight: "bold",
          },
        },
        xAxis: {
          categories: Object.keys(props.data),
        },
        yAxis: {
          title: {
            text: "Count",
          },
        },
        plotOptions: {
          series: {
            color: "rgb(68,114,196)",
          },
          column: {
            shadow: {
              color: "rgba(0,0,0,0.5)",
              width: 5,
              offsetX: 2,
              offsetY: 2,
            },
          },
        },
        series: [
          {
            name: "Density",
            data: Object.values(props.data),
          },
        ],
        tooltip: {
          shared: true,
          pointFormat:
            '<span style="color:{series.color}">{series.name}: <b>{point.y:.1f}</b><br/>',
        },
      });
    }
  }, [graphData]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}
